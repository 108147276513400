import { ZiedEmail } from "../../data/defaultData";
import ComponentSensors from "./ComponentSensors"
import ComponentSoftwares from "./ComponentSoftwares"
import ComponentSteps from "./ComponentSteps"

const ProductOneBrainMirror = () => {

    return (
        <>
         <section className="py-4 contact-hero">
            <div className="container py-1 py-md-5">
                <div className="text-container text-center text-white py-3 ">
                    <h1 className="font-weight-bold h1" >
                    BrainMirror Product
                    </h1>
                    <p className="font-20">
                    BrainMirror is a <b>"Neuro-Nurse On-demand"</b> technology with machine learning capabilities for remote diagnosis & monitoring of brain diseases.
                     
                    </p>
                </div>
            </div>
        </section>   
        <ComponentSensors/>
        <ComponentSoftwares/>
        <section className="bg-primary py-5 mb-5">
                <div className="container">
                    <h3 className="text-center text-white mb-4">Would you like to test our product?</h3>
                    <div className="row">
                        <div className="col-md-6 offset-md-3 text-center">
                        <a href={ZiedEmail} className="btn btn-dark mx-md-4">Request a demo</a>
                        </div>
                    </div>
                </div>
            </section>
        {/* <section className="py-5">
            <div className="container">
                <h2 className="text-center section-heading">How To Use Myelin-H Solution</h2>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                    <ComponentSteps/>
                    </div>
                </div>
            </div>
        </section> */}
        </>
    );
};

export default ProductOneBrainMirror;