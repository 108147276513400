import News01 from "./AllNews/News01";
import NewsHero from "./NewsHero/NewsHero";
import "./ViewNews.scss";
import Newsletter from "../Home/Newsletter/Newsletter";
import Oct22 from "./AllNews/2022/Oct22";
import newsDatabase from "../../data/news";
import NewsTemplateOne from "./NewsTemplates.js/NewsTemplateOne";
import NewsTemplateTwo from "./NewsTemplates.js/NewsTemplateTwo";
import React, { useEffect, useState } from "react";
import March2023 from "./March2023";
import May2023 from "./May2023";
import October2023 from "./October2023";
import December2023 from "./December2023";
import January2023 from "./January2023";
import March2024 from "./March2024";
import LatestNewsRaw from "../Home/LatestNews/LatestNewsRaw";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import April2024 from "../Home/LatestNews/April2024";
import June2024 from "../Home/LatestNews/June2024";
import August2024 from "../Home/LatestNews/August2024";

const ViewNews = () => {
    const [activeNews, setActiveNews] = useState(`August-2024`);

    const [newsList, setNewsList] = useState([
        `News-2022`,
        `March-2023`,
        `May-2023`,
        `October-2023`,
        `December-2023`,
        `January-2024`,
        `March-2024`,
        `April-2024`,
        `June-2024`,
        `August-2024`,
    ]);
    const reversedList = [
        `News-2022`,
        `March-2023`,
        `May-2023`,
        `October-2023`,
        `December-2023`,
        `January-2024`,
        `March-2024`,
        `April-2024`,
        `June-2024`,
        `August-2024`,
    ].reverse()
    return (
        <>
            <main>
                <NewsHero />
                <section className="py-3 d-none d-md-block text-center sticky-custom-news">
                    <ButtonGroup
                        className="custom-outline-group"
                        aria-label="Basic example"
                    >
                        {reversedList &&
                            reversedList.map((item) => (
                                <NewsButton
                                    setActiveNews={setActiveNews}
                                    data={item}
                                    activeNews={activeNews}
                                />
                            ))}
                    </ButtonGroup>
                </section>
                <section className="py-3 d-md-none text-center sticky-custom-news">
                   
                        {reversedList &&
                            reversedList.map((item) => (
                                <NewsButtonMobile
                                    setActiveNews={setActiveNews}
                                    data={item}
                                    activeNews={activeNews}
                                />
                            ))}
                </section>
                {activeNews === newsList[9] && <August2024 />}
                {activeNews === newsList[8] && <June2024 />}
                {activeNews === newsList[7] && <April2024 />}
                {activeNews === newsList[6] && <March2024 />}
                {activeNews === newsList[5] && <January2023 />}
                {activeNews === newsList[4] && <December2023 />}
                {activeNews === newsList[3] && <October2023 />}
                {activeNews === newsList[2] && <May2023 />}
                {activeNews === newsList[1] && (
                    <section>
                        <div className="container">
                            <March2023 />
                        </div>
                    </section>
                )}
                {activeNews === newsList[0] && (
                    <section>
                        <NewsTemplateOne data={newsDatabase[0]} />
                        <NewsTemplateTwo data={newsDatabase[1]} />
                        <Oct22 />
                    </section>
                )}

                <Newsletter />
            </main>
        </>
    );
};

export default ViewNews;

const NewsButton = ({ data, activeNews, setActiveNews }) => {
    return (
        <Button
            onClick={() => setActiveNews(data)}
            variant={data === activeNews ? "primary" : "secondary"}
        >
            {data}
        </Button>
    );
};
const NewsButtonMobile = ({ data, activeNews, setActiveNews }) => {
    return (
        <Button
            onClick={() => setActiveNews(data)}
            className="mx-2 my-1"
            variant={data === activeNews ? "primary" : "secondary"}
        >
            {data}
        </Button>
    );
};

const NewsTemplate = ({ data }) => {
    return (
        <>
            <article className="py-4 bg-light">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-5">
                            <h2 className="font-bold mb-1">{data.title}</h2>
                            <p className="mb-3">
                                <i>{data.date}</i>
                            </p>
                            <div
                                dangerouslySetInnerHTML={{ __html: data.text }}
                            ></div>
                        </div>
                        <div className="col-md-7">
                            <div className="row d-flex align-items-center">
                                <div className="col-5">
                                    <img
                                        src={data.img}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="col-7">
                                    <img
                                        src={data.imgTwo}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <a
                                    href={data.url}
                                    className="mt-4 btn btn-primary custom-round"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    VIEW DETAILS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};
