import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import { Fit4Start } from "../../../Images";

const AwardsAndTechnology = () => {
    return (
        <>
            <section className="py-5 bg-">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Awards</h2>
                    <HorizontalLine center="true" className="mb-3" />


                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-7 mb-5 mb-md-0 text-center">
                            <div className="m-0 px-5">
                                <div className="px-5">
                                    <img src="/awards/nasa-i-tech.png" alt="fit-4-start" className="m img-fluid" />
                                </div>
                            </div>
                            <h5 className="font-bold mt-4">NASA iTech Top 10 Finalist</h5>
                            <p className="text-center mb-4">
                            Myelin-H became one of the top 10 finalists in the NASA iTech competition.
                                {/* Myelin-H is among the winners of the <a className="link btn" href="https://en.wikipedia.org/wiki/Fit_for_Start">Fit4Start</a> program for this year. Read more: */}
                            </p>

                            {/* <div className="text-center">
                                <a href="https://www.linkedin.com/posts/myelinh_spacehealth-medtech-entrepreneurs-activity-6990727960425054208-6Dj8?utm_source=share&utm_medium=member_desktop" className="btn btn-primary custom-round">Read more...</a>
                            </div> */}

                        </div>
                        <div className="col-md-6 mb-5 mb-md-0 text-center">
                            <div className="m-0 px-md-5 m-md-5">
                                <div className="px-5">
                                    <img src="/awards/care-in-space.jpeg" alt="fit-4-start" className="m img-fluid" />
                                </div>
                            </div>
                            <h5 className="font-bold">One of the winners of the "Care-In-Space" challenge</h5>
                            <p className="text-center mb-4">
                            Myelin-H is excited to be selected as one of the winners of the "Care-In-Space" challenge by Boryung Corp., Axiom Space, and Starburst Aerospace.
                                {/* Myelin-H is among the winners of the <a className="link btn" href="https://en.wikipedia.org/wiki/Fit_for_Start">Fit4Start</a> program for this year. Read more: */}
                            </p>

                            <div className="text-center">
                                <a href="https://www.prnewswire.com/news-releases/boryung-axiom-space-and-starburst-aerospace-announce-six-winners-from-the-inaugural-2022-care-in-space-challenge-301658821.html" className="btn btn-primary custom-round">Read more...</a>
                            </div>

                        </div>
                        <div className="col-md-6 mb-5 mb-md-0 text-center">
                            <div className="m-0 px-md-5 m-md-5">
                                <div className="px-5">
                                    <img src="/images/awards/imec.png" alt="fit-4-start" className="img-fluid" />

                                </div>
                            </div>
                            <h3 className="font-bold ">imec's portfolio company</h3>
                            <p className="text-center mb-4">
                                Myelin-H is now part of the imec istart program and imec's portfolio companies... <br /> Read more:
                            </p>

                            <div className="text-center">
                                <a href="https://www.linkedin.com/feed/update/urn:li:activity:6955109487137464320/" className="btn btn-primary custom-round">Read more...</a>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

        </>
    );
};

export default AwardsAndTechnology;