import "./HorizontalLine.scss"

const HorizontalLine = ({ center, className }) => {

    return (
        <>
            {center ?
                <div class={"d-flex justify-content-center " + className}>
                    <br />
                    <div className="line"></div>
                </div> :
                <div class={"d-flex justify-content-start " + className}>
                    <br />
                    <div className="line"></div>
                </div>
            }
        </>
    );
};

export default HorizontalLine;