import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './Components/router/ScrollToTop';
import Main from './views/Main';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-163773560-1');
ReactGA.pageview(window.location.pathname + window.location.search);



const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Main />
      </Router>
    </>
  );
}
export default App;
