import React from "react";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./LatestNews.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const August2024 = () => {
    const img1 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/0a270ff6-6545-cdd5-98db-0f6673714397.jpeg`;
    const img2 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/7068f2d7-6a42-b4b2-12b6-6ab71e29d2f9.jpeg`;
    const img3 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/c46dbacc-3edf-a43b-4694-f6a269be4c8c.jpeg`;
    const img4 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/1f047f6c-2299-4b6d-6d70-658b8c6643e9.jpeg`;
    const img5 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/83f3c8cb-3181-ce95-bb6a-d963d7321c53.jpeg`;
    const img6 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/9c1aadd2-b2a2-7365-d94a-ff900604bd81.jpeg`;
    const img7 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/7158928b-a4f8-2d21-88c6-c7ffca2c4d3e.jpeg`;
    const img8 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/0b8c1769-6881-19ab-9faa-5e9dbbb4c891.jpeg`;
    const img9 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/a8c592e9-7f3e-6a3c-460e-ea821b6e5ece.jpeg`;
    const img10 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/8e0c5de3-0f8f-321c-1af6-4072d4be9fba.jpeg`;
    const img11 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/1fc6e54d-80a8-d0aa-9565-2ef981292cc0.jpeg`;
    const img12 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/7341a2e4-c00c-7e32-bae5-a0f613c402af.png`;
    const img13 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/2074b7a3-d712-b9ab-6bf5-bd67ab5d50a0.jpg`;
    return (
        <>
            {/* news 1 */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ META
                            </h2>
                            <p className="font-16 text-justify">
                                It was absolutely fabulous to visit Meta Reality
                                Labs at Meta. We had a fantastic opportunity to
                                try out some of Meta’s wristband interaction
                                capabilities, showcase a live demonstration of
                                Myelin-H’s brain-computer interface platform,
                                and discuss how both companies could collaborate
                                soon, to make a positive impact on the lives of
                                brain disorder patients. It was truly delightful
                                to visit their offices, and we are most grateful
                                for their splendid hospitality.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="img-card mt-4">
                                <img src={img1} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 2 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ UC San Diego Health{" "}
                            </h2>
                            <p className="font-16 text-justify">
                                We’re absolutely delighted to have had a
                                splendid meeting with one of the world’s leading
                                multiple sclerosis (MS) neurologists and the
                                brilliant team at UC San Diego Health in
                                California. It’s an honour to work with them on
                                our R-MMS clinical trial on remote monitoring
                                and neurorehabilitation of MS. We’re also
                                excited to explore the integration of our
                                brain-machine interface into various clinical
                                trials and projects, which will undoubtedly make
                                a jolly good difference in the lives of US
                                patients grappling with brain disorders.
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img2}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img3}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 3 */}
            <section className="py-5 ">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ NYU Langone Medical Centre in New
                                York City{" "}
                            </h2>
                            <p className="font-16 text-justify">
                                We are delighted to have met with the first-rate
                                team of neurologists, MS clinicians and
                                scientists at NYU Langone Medical Centre in New
                                York City to present the Myelin-H brain-machine
                                interface technology, as well as to delve into
                                our collaboration plan and the R-MMS clinical
                                trial on remote monitoring and
                                neurorehabilitation for multiple sclerosis.
                                What’s more, it was a cracking opportunity to
                                discuss with the team at the Multiple Sclerosis
                                Comprehensive Care Centre at NYU Langone Health
                                other prospective clinical projects that
                                dovetail perfectly with our US expansion plans.
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img4}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img5}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 4 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ Stanford Medicine in Palo Alto
                            </h2>
                            <p className="font-16 text-justify">
                                It was lovely to visit our long-standing friends
                                at Stanford Medicine in Palo Alto. This meeting
                                was truly the crown jewel of our West Coast
                                sojourn, offering us the delightful opportunity
                                to present a live demonstration of our Myelin-H
                                BCI technology, engage in discussions on the
                                R-MMS clinical trial, and discuss a variety of
                                other possible clinical projects with their
                                exceptional medical team. We deeply appreciate
                                their impeccable hospitality and look forward to
                                collaborating with you.
                                <br />
                                Following this, we had the pleasure of visiting
                                the Consulate General of Luxembourg in San
                                Francisco, where we discussed our ambitious US
                                expansion plans and shared some of Myelin-H’s
                                recent progress.
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img6}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img7}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* news 5 */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H will be speaking @ the MedTech Malta
                                2024 conference
                            </h2>
                        </div>
                        <div className="col-md-5">
                            <p className="font-16 text-justify">
                                Myelin-H is absolutely thrilled and honoured to
                                be speaking at the MedTech Malta 2024 conference
                                ({" "}
                                <a
                                    href="https://med-tech.world/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://med-tech.world/
                                </a>{" "}
                                ). This prestigious annual event, curated by{" "}
                                <strong>
                                    MedTech World, will be held from the 6th to
                                    the 8th of November 2024 at the illustrious
                                    Mediterranean Conference Centre in Valletta,
                                    Malta
                                </strong>
                                . The conference is a key gathering for
                                companies, start-ups, investors, professionals,
                                and media within the MedTech and healthcare
                                technology sectors. This year’s event is set to
                                welcome over 1,500 distinguished guests and
                                luminaries from across the globe.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="img-card mt-4">
                                <img src={img8} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 6*/}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ Ascend conference in Las Vegas with
                                Redwire Space, Axiom Space, and Boryung
                            </h2>
                            <p className="font-16 text-justify">
                                Myelin-H is chuffed to have participated in the
                                Ascend conference in Las Vegas and to have
                                shared the stage with such brilliant minds as
                                Mike Gold from Redwire Space, Pearly Pandya from
                                Axiom Space, Jack Dongjoo Lim & Betty from
                                Boryung, and John Ralston from Neursantys. What
                                a truly smashing experience and a most
                                thought-provoking discussion on the future of
                                humanity in space—particularly how space health
                                might chart a course for a more sustainable
                                presence on the Moon, Mars, and beyond.
                                <br />
                                Whilst Myelin-H’s core focus remains on
                                developing brain-computer interface (BCI)
                                technology for universal brain health and
                                recovery for those of us on Earth, it was simply
                                marvellous to share our space health
                                journey—from our NASA iTech selection to
                                receiving the Care in Space Award in 2022. We
                                also had the opportunity to shine a light on how
                                we are leveraging our medical BCI technology to
                                monitor, train, and enhance astronauts'
                                cognitive abilities before, during, and after
                                deep space missions. Indeed, the future of space
                                health is looking extraordinarily bright.
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img9}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mt-4">
                                        <div className="img-card">
                                            <img
                                                src={img10}
                                                alt=""
                                                className=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 7 */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-5">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H @ Consulate General of Luxembourg in
                                New York
                            </h2>
                            <p className="font-16 text-justify">
                                It was fabulous to visit the Consulate General
                                of Luxembourg in New York at The Luxembourg
                                House to discuss our US expansion plans and
                                share some of Myelin-H's recent progress. Very
                                nice to have met you Kıvanç Ergu, PhD and
                                Jerrold Seeman, Esq. We are immensely grateful
                                for your gracious hospitality, and for the
                                invaluable connections you have facilitated for
                                Myelin-H on the East Coast.
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="img-card mt-4">
                                <img src={img11} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 8 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12"></div>
                        <div className="col-md-4">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Exciting News: Myelin-H Expands to the US with a
                                New Boston Office!
                            </h2>
                            <p className="font-16 text-justify">
                                We are thrilled to announce that Myelin-H has
                                officially landed in the United States! Our
                                American entity is now fully operational, and we
                                are excited to welcome you to our stunning new
                                office at{" "}
                                <u className="text-primary">
                                    265 Franklin St. Ste 1702, Boston, MA 02110
                                </u>{" "}
                                . Stay tuned for more updates and visit us soon
                                at our vibrant new Boston location!
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="img-card mt-4">
                                <img src={img13} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 9 */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Myelin-H is hiring 🚀
                            </h2>
                        </div>
                        <div className="col-md-8">
                            <p className="font-16 text-justify">
                                We are looking for a talented game developer to
                                join our team. Do you have a good grasp of game
                                development, wearable sensors and software
                                engineering? If yes, this role is for you. If
                                you are excited enough about Myelin-H's mission
                                to transform the lives of patients with brain
                                diseases, please consider applying.
                                <br />
                                Apply here:{" "}
                                <a
                                    href="https://www.linkedin.com/jobs/view/4007840175"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    click here.
                                </a>
                                <br />
                                We strongly recommend reading our blog post,
                                which provides insights into Myelin-H,
                                demystifies our recruitment process, and
                                unravels avoidable banana skins we have observed
                                in previous applications:
                                <a
                                    href="https://lnkd.in/ekEPdk8R"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://lnkd.in/ekEPdk8R
                                </a>
                            </p>
                        </div>
                        <div className="col-md-5">
                            <div className="img-card mt-4">
                                <img src={img12} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default August2024;
