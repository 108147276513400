import React from 'react';
import HorizontalLine from '../../Components/common/HorizontalLine/HorizontalLine';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import YouTubeResponsiveEmbed from '../../Components/YouTubeResponsiveEmbed';
const TechCognitiveGames = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <section id="solution" className="py-5">
                <div className="container">
                    <h2 className="h2 text-primary text-center section-heading">Cognitive Games</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <p className="mb-4 font-bld font-22 text-center">
                    Our <strong>cognitive</strong> and <strong>mobile</strong> games, taking inspiration from neuroscience, allow individuals with brain disorders to assess their <strong>cognitive &amp; physical</strong> <strong>performance</strong> regularly and can help them <strong>enhance</strong> their <strong>cognitive abilities</strong>.

                    </p>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                        <YouTubeResponsiveEmbed youTubeId={"AoF1Zf-EHuA"} />

                        </div>
                    </div>
                    {/* <Carousel activeIndex={index} onSelect={handleSelect} slide={true} pause="hover" interval="1200" controls={false} indicators={true}>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/0.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/1.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/1.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/2.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/2.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/3.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/3.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/4.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/4.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/5.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center ">

                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/5.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                    <div className="col-md-6 mt-4">
                                        <img src="/tech/games/6.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        

                    </Carousel> */}
                </div>
            </section>
        </>
    );
};

export default TechCognitiveGames;