import { useEffect } from "react";
import AboutOurStory from "../Home/AboutOurStory/AboutOurStory";
import Newsletter from "../Home/Newsletter/Newsletter";
import TeamAndAdvisory from "../Home/TeamAndAdvisory/TeamAndAdvisory";
import ContactForm from "./ContactForm/ContactForm";
import AboutHero from "./ContactHero/AboutHero";
import ContactHero from "./ContactHero/AboutHero";
// import { firebaseAnalytics } from "../../firebase/fire-app";

const ViewAbout = () => {
    document.title = "Myelin-H | About Us";
    // useEffect(() => {
    //     firebaseAnalytics.logEvent("contactpage_visited");
    // }, [])
    return (
        <>
            <main>
                <AboutHero />
                <AboutOurStory />

                <TeamAndAdvisory />
                <Newsletter />
            </main>
        </>
    );
};

export default ViewAbout;