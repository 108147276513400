import React from "react";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import ourSoftwares from "./oursoftware-data";
import SoftwareCard from "./SoftwareCard";

const OurSoftwareProducts = () => {
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center text-primary section-heading">
                        Our Software Products
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                </div>
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <SoftwareCard col="4" data={ourSoftwares[0]} />
                        <SoftwareCard col="4" data={ourSoftwares[1]} />
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurSoftwareProducts;
