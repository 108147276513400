import { Link } from "react-router-dom";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import YouTubeResponsiveEmbed from "../../../Components/YouTubeResponsiveEmbed";
import whatWeDoData from "./data";
import "./WhatWeDo.scss"
import { myelinhDemoVideoYTLink } from "../../../data/site-data";

const WhatWeDo = () => {

    return (
        <>
            <section className="what-we-do py-5 bg-light">
                <div className="container">
                    <div className="h2 text-center section-heading text-primary">What We Do</div>

                    <HorizontalLine center="true" className="mb-0" />
                    <p className="mb-4 text-center font-22 ">

                    Myelin-H is developing <span className="font-bold">brain-computer interface</span> technology for remote <span className="font-bold">brain health assessment</span> and <span className="font-bold">neurorehabilitation</span>, essentially creating an <span className="font-bold">operating system for the brain</span>.

                    </p>


                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Our Technology</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <p className="mb-3 font-22 font-bld text-center">

                    Myelin-H blends <span className="font-bold">cognitive games, visor-like</span> sensors and advanced <span className="font-bold">artificial intelligence software</span> for brain health monitoring and recovery. Clinical Insights and <span className="font-bold">personalised neurotherapeutic</span> programmes are generated, <span className="font-bold">anywhere, anytime</span>.

                    {/* Myelin-H blends <strong>cognitive games</strong>, <strong>visor-like </strong>sensors and advanced <strong>neuromorphic software </strong>for <strong>brain health monitoring</strong>. Insights, recommendations, and results can be <strong>shared</strong> with physicians, <strong>anywhere</strong>, <strong>anytime</strong>. */}
                    </p>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                            <YouTubeResponsiveEmbed youTubeId={"x8Wj8YEED9s"} />
                            {/* <YouTubeResponsiveEmbed youTubeId={"VBZ76e3sC8Y"} /> */}
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Link to="/science-and-technology" className="btn btn-primary btn-lg">Learn More</Link>
                    </div>

                </div>
            </section>
        </>
    );
};

export default WhatWeDo;

const ListItem = ({ doc }) => {
    return (
        <li className="w-100 item">
            <div class="content py-3">
                <i class="las la-mask"></i>
                <div>
                    <h3 >{doc.title} </h3>

                    <p dangerouslySetInnerHTML={{ __html: doc.description }} ></p>
                </div>
            </div>
        </li>
    )
}