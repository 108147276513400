import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine"
import SensorOne from "./img/sensor1.jpeg"
import SensorTwo from "./img/sensor2.jpeg"

const ComponentSensors = () => {
    return (
        <>
            <section className="py-5 bg-white">
                <div className="container">
                    <h2 className="h2 text-center section-heading">
                        Sensors
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 offset-md-1">
                            <div>
                                <img src={SensorOne} alt="" className="img-fluid" />
                            </div>
                            <h3 className="h4 text-center font-weight-bold">
                                FDA cleared & CE-Marked Brain Headset
                            </h3>
                            <p>
                                A wearable and wireless brain sensor with 32 channels. The sensor is <b>CE-certified and FDA cleared wearable</b>.
                            </p>
                        </div>
                        {/* <div className="col-md-5 mt-5 mt-md-0">
                            <div>
                                <img src={SensorTwo} alt="" className="img-fluid" />
                            </div>
                            <h3 className="h4 font-weight-bold"><br/>
                                Microscopic Saliva Imaging System
                            </h3>
                            <p><br/>
                                Portable, wireless, and certified microscopic camera.
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComponentSensors;