import React from "react";
import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import YouTubeResponsiveEmbed from "../../Components/YouTubeResponsiveEmbed";

const MissionSection = () => {
    return (
        <>
            <section id="b" className=" py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-center">
                            <h2 className="h2 text-center text-primary section-heading">
                                Mission
                            </h2>
                            <HorizontalLine center="true" className="mb-3" />
                            <p className="mb-5 font-22 font-bld text-center">
                                Our mission is to empower over <b>a billion</b> individuals with <b>brain disorders</b>, including  <b>2.8 million</b> with multiple sclerosis, with <b>revolutionary </b>
                                brain health technology <b>to prevent</b> mental and physical <b>disabilities.</b>

                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="b" className="bg-light py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-center">
                            <div className="row mb-4 d-flex align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <h2 className="h2 text-center text-primary section-heading">
                                        Vision: Brain Care on Earth
                                    </h2>
                                    <HorizontalLine
                                        center="true"
                                        className="mb-3"
                                    />
                                    <p className="mb-5 font-22 font-bld text-center">
                                    We are creating the future of <span className="text-bold">personalised</span> brain health <span className="font-bold">monitoring and repair</span>.
                                    </p>
                                </div>
                                {/* <div className="col-md-6">
                                    <YouTubeResponsiveEmbed
                                        youTubeId={"WoiQvDNLhdc"}
                                    />
                                </div> */}
                            </div>

                            <div className="row d-flex align-items-center">
                                <div className="col-md-6">
                                    <h2 className="h2 text-center text-primary section-heading">
                                        Vision: Brain Care in Space
                                    </h2>
                                    <HorizontalLine
                                        center="true"
                                        className="mb-3"
                                    />
                                    <p className="mb-5 font-22 font-bld text-center">
                                    Beyond Earth, our brain interface <strong>monitors and cognitively trains astronauts </strong>before, during, and after deep space missions.
                                    </p>
                                </div>
                                <div className="col-md-6 order-md-1">
                                    <YouTubeResponsiveEmbed
                                        youTubeId={"odnhOU44iZw"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MissionSection;
