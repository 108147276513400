import React from 'react';
import "./software.scss";

const SoftwareCard = ({ data, col }) => {
    return (
        <>
            <div className={`col-md-${col ? col : 5} mb-4`}>
                <div className="career-card">
                    <img src={data.img} alt="" className="img-fluid" />
                    <div className="bg-light py-4 px-3 pb-5">
                        {/* <p className="font-14 mb-3">{data.title}</p> */}

                        <h4 className='font-bold no-break'>{data.title}</h4>
                        <p className='product-description font-18 mb-4' dangerouslySetInnerHTML={{ __html: data.description }} ></p>
                        {/* <button className="btn btn-outline-primary">VIEW DETAILS</button> */}
                        {/* <a href={data.detailsLink} className="btn btn-outline-primary" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a> */}
                        <a href={data.applyLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">{data.name}</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SoftwareCard;