import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaPlayCircle } from "react-icons/fa"
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import ScrollTrigger from 'react-scroll-trigger';
import "./ConceptVideo.scss"


const ConceptVideo = () => {
    const [modalShow, setModalShow] = useState(false);
    const changeModalState =(bool)=>{
        setTimeout(()=>{
            setModalShow(bool)
        },1050)
    }

    const onModalShow = () => {
        console.log("show");
        setModalShow(true)
    }
    const ThumbNail = "/concept.png"
    return (
        <>
         <section className="video-section bg-light py-5" id="value-proposition "  >
                <div className="container py-5">
                    <h2 className="heading-shadow text-center text-white section-heading h1">Concept</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center">
                            <ScrollTrigger  onEnter={() => changeModalState(true)}  onExit={() => changeModalState(false)}>

                                <div className="video-container">
                                    {modalShow ? <YouTubeVideoFrame/>: <div>
                                        <img src={ThumbNail} className="img-fluid" alt="" />

                                        <div onClick={onModalShow} className="play-btn">

                                            <FaPlayCircle />
                                        </div>
                                    </div>}
                                </div>
                            </ScrollTrigger>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={false}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Myelin-H Space Startup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe title="Myelin-H"  className="w-100" height="500" src="https://www.youtube.com/embed/WoiQvDNLhdc?autoplay=1&mute=1&enablejsapi=1" frameborder="0" autoplay="autoplay" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-dark" onClick={() => setModalShow(false)}>Close</button>
                </Modal.Footer>
            </Modal>   
        </>
    );
};

export default ConceptVideo;

const YouTubeVideoFrame = () => {
    return (
        <>
        <iframe title="Technology"  className="w-100  technology-video" src="https://www.youtube.com/embed/WoiQvDNLhdc?autoplay=1&enablejsapi=1" frameborder="0" autoplay="autoplay" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
        {/* <iframe title="Technology"  className="w-100  technology-video" src="https://www.youtube.com/embed/aYB-GJiWHg4?autoplay=1&enablejsapi=1" frameborder="0" autoplay="autoplay" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe> */}
        </>
        )
}