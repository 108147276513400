import React from "react";

const CompIntroduction = () => {
    return (
        <>
            <section className="py-5 contact-hero">
                <div className="container py-1 py-md-5">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold h1">Privacy Policy</h1>
                    </div>
                </div>
            </section>
            <section className="bg-light py-5">
                <div className="container">
                    <h2 className="text-center">BrainMirror Product</h2>
                    <h3 className="mb-4">Privacy Policy & Terms of Use</h3>
                    <h3>1. Introduction</h3>
                    <ul className="text-justify">
                        <li>
                            <strong>Intended Purpose:</strong> The BrainMirror
                            Membership includes a wireless Brain Device (Visor
                            for EEG recording), a wireless EMG Device (arm and
                            leg band), a mobile device, and a Battery Pack, plus
                            access to the MyelinFace app, which provides
                            personalised brain health and body insights,
                            cognitive games for health tracking, cognitive games
                            for physical and cognitive enhancement, and built-in
                            coaching and recommendations features. This provides
                            you with a big-picture look at brain & body health
                            and helps you boost and enhance your cognitive and
                            physical abilities. Disclaimer: BrainMirror product
                            is NOT a medical device for diagnosing disease or
                            illness. It is not intended to be a substitute for
                            professional medical advice and should not be relied
                            on as such.
                        </li>
                        <li>
                            <strong>The Services:</strong> The service allows
                            you to track, manage and share brain
                            wellness-related information collected by the
                            BrainMirror Device and games. To ensure the safe and
                            responsible management of our website and mobile
                            applications for all users, your utilisation of the
                            Services is governed by this Agreement and the
                            Privacy Policy. Myelin-H reserves the right to amend
                            this Agreement, the Privacy Policy, Content, and/or
                            the Services at any time, with such modifications
                            becoming effective upon their posting to the
                            Services. By continuing to access or use the
                            Services following such modifications, you consent
                            to be bound by the revised Agreement, Privacy
                            Policy, Content, and/or Services, as applicable.
                        </li>
                        <li>
                            <strong>Registration & Terms of Use:</strong> To
                            access the BrainMirror Membership (paid version from
                            the v2.0 release), you are required to subscribe and
                            pay any applicable subscription fees (“Membership
                            Fees”) promptly. By doing so, you agree to furnish
                            accurate, truthful, and complete information and to
                            ensure the ongoing accuracy and currency of your
                            Account details. You must refrain from selecting or
                            using a username that: Represents another individual
                            to impersonate them, or Infringes upon the rights of
                            any person other than yourself without proper
                            authorisation. You bear sole responsibility for all
                            activities conducted under your Account or password,
                            and for maintaining the confidentiality and security
                            of your Account password. You are prohibited from
                            using another person’s account or registration
                            information for the Services without explicit
                            permission. Promptly inform us of any changes in
                            your eligibility to utilise the Services, instances
                            of security breaches, or unauthorised access to your
                            Account or password. Never disclose your Account
                            login information through publication, distribution,
                            or posting. You retain the capability to delete your
                            Account, either directly or by requesting assistance
                            from one of our employees or affiliates. Myelin-H
                            shall not be held liable for any losses or damages
                            resulting from your non-compliance with this
                            section.
                        </li>
                        <li>
                            <strong>Eligibility:</strong> You affirm and
                            guarantee that you are at least 13 years old.
                            However, if you are below the legal age required to
                            enter into a binding contract in your jurisdiction,
                            your usage of the Services is contingent upon
                            obtaining parental consent. In such instances: Your
                            supervising parent or legal guardian assumes the
                            role of the user under this Agreement and is
                            accountable for all activities. You assert that your
                            parent or legal guardian has reviewed and consented
                            to this Agreement and the Privacy Policy on your
                            behalf. Individuals under the age of 13 are strictly
                            prohibited from using the Services, regardless of
                            circumstances or reasons. We retain the right, at
                            our sole discretion, to decline the provision of the
                            Services to any individual or entity, and to modify
                            the eligibility criteria at any time. Access to the
                            Services will be rescinded if this Agreement is
                            violated or if use of the Services is prohibited by
                            law.
                        </li>
                        <li>
                            <strong>Membership Service:</strong> Myelin-H offers
                            two Membership options for its BrainMirror product –
                            an Annual, and a 24-month plan. When you sign up,
                            you’ll have access to the BrainMirror device and
                            platform, full app access. If you want to continue
                            your membership after your 1-month free trial,
                            you’ll automatically be subscribed to our 12-month
                            plan with the possibility of choosing a month-month
                            payment plan. Within the trial period, if
                            BrainMirror is not suitable for you, just cancel and
                            send your BrainMirror device back.
                        </li>
                        <li>
                            <strong>Privacy Policy Overview:</strong> Myelin-H
                            LTD is on a mission to empower over a billion
                            individuals suffering from neurological disorders
                            and unlock the human brain's potential. We are your
                            path to advanced and personalised brain health
                            monitoring. Yet, we exist to enhance your life, not
                            intrude upon it. Hence, we take user privacy
                            seriously and we aim for you to comprehend how we
                            utilise, gather, and distribute personal data, along
                            with the precautions we implement to safeguard your
                            Personal Data. This Privacy Policy pertains to
                            Personal Data we gather about members and other
                            users who engage with the Myelin-H App '
                            <strong>BrainMirror</strong>' or utilise our
                            services, encompassing interactions such as visiting
                            our websites, social media pages, or utilising our
                            mobile apps, including the BrainMirror device
                            (collectively referred to as the “Services”).
                            However, this Privacy Policy does not encompass the
                            practices of companies beyond our ownership or
                            control, nor individuals we do not manage. We
                            disclaim responsibility for the policies and
                            practices of any third parties, and we neither
                            control, operate, nor endorse any information,
                            products, or services that may be provided by third
                            parties or accessible through the Services.
                        </li>
                        <li>
                            <strong>Use of the Services:</strong> The Services
                            provide access to Myelin-H games, software, text,
                            graphics, images, video, audio, data, and other
                            technology and material (collectively referred to as
                            the “Content”). This Content, along with the
                            Services, incorporates proprietary and third-party
                            advanced technologies such as artificial
                            intelligence, machine learning systems, and similar
                            features (referred to collectively as “AI
                            Technology”), including third-party large language
                            models (“LLM”). Subject to the terms outlined in
                            this Agreement and your BrainMirror Membership, we
                            grant you a worldwide, non-exclusive,
                            non-sublicensable, and non-transferable limited
                            licence to: Access and utilise (i.e., download and
                            display locally) the Content, software, and
                            applications provided through the Services. Utilise
                            the BrainMirror software embedded within the
                            BrainMirror Device (the “BrainMirror Device”),
                            solely to utilise the Services. Any use,
                            reproduction, modification, distribution, or storage
                            of the Content for purposes other than utilising the
                            Services is explicitly prohibited without prior
                            written consent from us. You are prohibited from
                            selling, licensing, renting, or exploiting any
                            Content for commercial use or in any manner that
                            infringes upon third-party rights. The Content may
                            either be owned by us or provided through agreements
                            with others, including other Services users,
                            licensors, partners, sponsors, or affiliates. It is
                            protected by intellectual property rights, including
                            copyright laws in various jurisdictions.
                            Unauthorised use of the Content may constitute a
                            violation of copyright, trademark, and other
                            applicable laws. Except for rights concerning any
                            User Content (as defined below), you have no
                            entitlement to the Content and may only use it as
                            permitted under this Agreement. Any other usage
                            requires prior written consent from us or the
                            Content owner. You must retain all copyright and
                            other proprietary notices contained within the
                            original Content on any copies you create. Selling,
                            transferring, assigning, licensing, sublicensing,
                            modifying, reproducing, displaying, publicly
                            performing, creating derivative works of,
                            distributing, or otherwise using the Content for any
                            public or commercial purpose is strictly prohibited.
                            Violation of any aspect of this Agreement results in
                            automatic termination of your permission to access
                            and/or use the Content and Services, requiring
                            immediate destruction of any Content copies you
                            possess.
                        </li>
                        <li>
                            <strong>Payment of Fees</strong> <br />
                            a. Fees: You agree to pay the Membership Fees
                            (either yearly or every 24 months) as outlined in
                            this Agreement and specified during the purchase and
                            payment process. Any payment terms presented to you
                            during the Membership acquisition process are
                            considered part of this Agreement and are hereby
                            incorporated by reference. <br />
                            b. Billing: We may collect payments directly from
                            you, or we may utilise a third-party payment
                            processor ("Payment Processor") to bill you through
                            a payment account linked to your Account on the
                            Services (referred to as your "Billing Account") for
                            your Membership. The processing of payments by a
                            Payment Processor will be subject to the terms,
                            conditions, and privacy policies of the Payment
                            Processor, in addition to this Agreement. We are not
                            liable for any errors made by a Payment Processor.
                            By opting to purchase a Membership, you consent to
                            pay us, either directly or through a Payment
                            Processor, all Membership Fees and other charges at
                            the then-current prices and in accordance with the
                            applicable payment terms. You authorise us, via a
                            Payment Processor, to charge your selected payment
                            provider (your "Payment Method"). You agree to make
                            payment using your chosen Payment Method. We retain
                            the right to rectify any errors or mistakes made by
                            us or a Payment Processor, even if payment has
                            already been requested or received. <br />
                            c. Recurring Billing: The Membership payment terms
                            may include recurring period charges as agreed upon
                            by you. By selecting a recurring payment plan, you
                            acknowledge that such Membership Fees entail an
                            initial and recurring payment feature, and you
                            assume responsibility for all recurring charges
                            until cancellation.
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default CompIntroduction;
