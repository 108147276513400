import {FcGoogle} from "react-icons/fc"
import {AiFillRocket} from "react-icons/ai"
import {FaGlobeAmericas} from "react-icons/fa"
const GoogleIcon =()=> <FcGoogle/>;
const NasaIcon =()=> <AiFillRocket/>;
const WebsiteIcon =()=> <FaGlobeAmericas/>
const teamData = [
    // {
    //     serial: 1,
    //     name: `Dr. ZIED TAYEB`,
    //     img: `/team/zied_tayeb.jpeg`,
    //     twitter: `https://twitter.com/Zied_Tayeb`,
    //     linkedin: `https://www.linkedin.com/in/zied-tayeb/`,
    //     web: ``,
    //     officeEmail: `zied.tayeb@myelinh.com`,
    //     youtube: `https://www.youtube.com/channel/UChFahuA5Xoo_JkfrBqPgXFg/videos?view_as=subscriber`,
    //     fb: ``,
    //     shortDescription: `
    //     <p>
    //     I am the founder and CEO of Myelin-H, a company on a mission to empower over a billion individuals suffering from neurological disorders. We achieve this through a non-invasive, closed-loop brain-machine interface for remote monitoring and neurorehabilitation. My passion for this mission stems from a personal story that drives my commitment to making a difference in people's lives. Over and above, I am also an associate professor of neurotechnology in the UK. I hold a PhD in neuroscience and was trained as a computer scientist. I was selected by NASA as one of the top 10 innovators in 2019, nominated for the International Brain-Computer Interface (BCI) Award in 2020, and recognised by the UK's Royal Academy of Engineering as a global leader in neurotechnology in 2022.
    //     </p>

    //     <p>&nbsp;</p>
        
    //     <p>
    //     Looking ahead, my vision is to empower health professionals with revolutionary brain-machine interface technology, shaping the future of personalised medicine and brain health.

        
    //     </p>
    //     `,
    //     references: [
    //         {
    //             text: "Google Scholar",
    //             link: `https://scholar.google.com/citations?user=ajSjVHkAAAAJ&hl=en`,
    //             icon: "google"
    //         },
    //         {
    //             text: "Süddeutsche Zeitung",
    //             link: "https://www.sueddeutsche.de/muenchen/wissenschaft-weltraumrobotern-das-fuehlen-beibringen-1.4698857",
    //             icon: "web"
    //         },
    //         {
    //             text: "NASA website",
    //             link: "https://www.nasa.gov/directorates/spacetech/itech/2019_meet_the_innovators_MyelinS/",
    //             icon: "nasa"
    //         },
    //         {
    //             text: "Paperjam",
    //             link: "https://paperjam.lu/article/the-more-we-make-mistakes-the-",
    //             icon: "web"
    //         }
    //     ]
    // },
    // {
    //     serial: 2,
    //     name: `SAMAHER GARBAYA`,
    //     img: `/team/samaher_garbaya.jpeg`,
    //     officeEmail: `samaher.garbaya@myelinh.com`,
    //     twitter: `https://twitter.com/SamaherGarbaya`,
    //     linkedin: `https://www.linkedin.com/in/samaher-garbaya-89864a135/`,
    //     web: ``,
    //     youtube: `https://www.youtube.com/channel/UCSYuRJyx_JWoxxunGE2Fw0g`,
    //     fb: ``,
    //     shortDescription: `<p>Samaher Garbaya is the Co-Founder &amp; COO of Myelin-H software company. Her major responsibilities in the company include managing the company on a daily basis, business development, product development, and finance. She is also pursuing her master&#39;s degree in space exploration at the University of Luxembourg. &nbsp;</p>

    //     <p>Prior to Myelin-H, Samaher Garbaya received her engineering diploma in applied mathematics and financial modeling from the National Superior Engineering School of Tunis, Tunisia, in July 2018. From February to June 2018, she was a research intern at the LIS lab at the University of Toulon in France, where she worked on developing a deep learning algorithm for object detection, recognition, and localization. From September to November 2018, she worked as an IT consultant at AxeFinance, a well-known fintech company. Samaher Garbaya was selected by the Luxembourg space agency as <strong>top 5 best innovators</strong> in 2019. Since March 2020, Samaher has been a group leader at the Space Generation Advisory Council (SGAC). Samaher&#39;s background and current interests span from statistics, mathematical modeling, software development, and financial modeling.</p>
    //     `,
    //     references: [
    //         {
    //             text: "Space Generation",
    //             link: 'https://spacegeneration.org/announcement-of-the-winners-of-the-sgac-lsa-newspace-europe-2019-pitch-competition-grant',
    //             icon: "web"
    //         }
    //     ]
    // },
   

    {
        serial: 3,
        name: `LYNETTE TAN`,
        img: `/team/lynette.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/lynette-tan-795ab927/`,
        web: ``,
        youtube: ``,
        fb: ``,
        shortDescription: `<p>Lynette Tan is the CEO of the Singapore Space and Technology Limited (SSTL), Asia Pacific's leading pioneer space organization. </p>

        <p>For her efforts and accomplishments, Lynette Tan was awarded the “Lancome Visionary Award” by one of Singapore’s leading female magazines, which also recognized her for her “Outstanding Contributions to the Science & Technology Industry”. Her 17 years of work experience includes a management position in the M&A division of a global pharmaceutical company. She also spent 6 years in the Singapore government service, which included a role as the center director in the Asia Pacific team. Lynette received her Master of Science in Chemical Engineering from the University of Stanford, and the highest distinction and the Alumni Award for her undergraduate studies in Chemical Engineering at the University of Illinois – Urbana Champaign. She was a recipient of the prestigious Sundstrand-EDB scholarship in 1999 which awards only one scholar a year.</p>
        `
    },
//     {
//         serial: 5,
//         name: `Sufian Butaineh`,
//         img: `/team/sufian.jpeg`,
//         twitter: ``,
//         linkedin: `https://www.linkedin.com/in/sufian-bataineh-5bb78a8/`,
//         web: ``,
//         youtube: ``,
//         fb: ``,
//         shortDescription: `<p>
//         Mr. Sufian Butaineh is the Chief Investment Officer and as a Chief Legal Officer at Myelin-H. Mr. Bataineh holds two master's degrees in law; one in Banking, Corporate, and Finance Law from the Fordham University School of Law, New York, USA, and the second one in Commercial, European, and International Law from the University of Sheffield in the UK, as well as a Postgraduate Certificate in Business Administration.</p>
// <p>Mr. Bataineh has a substantial work experience between Luxembourg, the USA, and the UK, in banking and finance, corporate law, investment funds, and securitizations. With his 20+ years of experience, Mr. Bataineh will work alongside our leadership team to leverage his network in corporations, financial institutions, investment funds, and HNWIs, as well as to look after Myelin-H's legal matters and co-lead our negotiations with our various stakeholders. 
//         </p>  `,
    
//     },
    {
        serial: 4,
        name: `DR. MED. Alessandro Pincherle`,
        img: `/images/team/pincherle.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/alessandro-pincherle-11b4b0183/`,
        web: ``,
        youtube: ``,
        fb: ``,
        shortDescription: `<p>Neurologist RC Hospital, Doctor of Medicine</p>
        `
    },
    // {
    //     serial: 6,
    //     name: `Ali Stuckey`,
    //     img: `/images/team/ali-stuckey.jpeg`,
    //     twitter: ``,
    //     officeEmail: `ali.stuckey@myelinh.com`,
    //     linkedin: `https://www.linkedin.com/in/ali-stuckey/`,
    //     web: ``,
    //     youtube: ``,
    //     fb: ``,
    //     shortDescription: `<p>Digital Media Specialist</p>,
    //     `,
    //     references: [   ]
    // },

]
export { teamData };