import React from "react";

const January2023 = () => {
    return (
        <>
            {/* <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <h2 className="font-bold  mb-1">
                                Recent visit to Addenbrooke's Hospital
                            </h2>
                            <p>
                                We are absolutely delighted and excited to share
                                our recent visit to{" "}
                                <strong>Addenbrooke’s Hospital</strong> and the{" "}
                                <strong>University of Cambridge</strong>{" "}
                                Hospitals on the Cambridge Biomedical Campus,
                                one of the first and most prestigious clinical
                                centers in the UK.
                            </p>
                            <p>
                                {" "}
                                <br />
                                We discussed Myelin-H's clinical trial plans,
                                our collaboration, and how Myelin-H's technology
                                could be used to monitor remyelination in MS
                                patients, as well as other clinical projects to
                                empower MS patients with Myelin-H's
                                brain-machine interface solutions for remote
                                monitoring and neurorehabilitation.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="https://media.licdn.com/dms/image/D4E22AQFA_N4y7L1Z7g/feedshare-shrink_2048_1536/0/1702491920364?e=1707350400&v=beta&t=TOlixLAr28Drqv6Gv5o_x73aTDic7nk2sTgJ0ULZ0Xs"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-3">
                            <img
                                src="https://media.licdn.com/dms/image/D4E22AQHOzlbMedwARg/feedshare-shrink_800/0/1702491918601?e=1707350400&v=beta&t=4QQDITaLVzACkcb5fsPNO-xs2L-5jSAQ0RRWfEPQcU4"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-4">
                            <img
                                src="https://media.licdn.com/dms/image/D4E22AQG4yzR4h0oi7w/feedshare-shrink_2048_1536/0/1702053103358?e=1707350400&v=beta&t=AqLcdrvn__R09gWUiBV_NLp8xvo_H8EO1yISNzU4oJA"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-3">
                            <img
                                src="https://media.licdn.com/dms/image/D4E22AQG_17rk4bzHhw/feedshare-shrink_2048_1536/0/1702053102959?e=1707350400&v=beta&t=ibDGTvr_l3aB9i0wvEAftcNWPxtgSmbrFoQTLgmpWVA"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        
                        <div className="col-md-5">
                            <h2 className="font-bold  mb-1">
                                Recent visit to National MS Center
                            </h2>
                            <p>
                                We are delighted to share our recent visit to
                                the National MS Center in Melsbroek, Belgium,
                                one of the largest clinical centers and
                                hospitals for multiple sclerosis in Europe.
                                <br />
                                We discussed Myelin-H's clinical trial plans,
                                collaboration, and other clinical projects to
                                empower MS patients with Myelin-H's
                                brain-machine interface solutions for remote
                                monitoring and neurorehabilitation.
                                <br />
                                We look forward to exploring exciting
                                possibilities for collaboration that promise to
                                redefine the future of personalised brain health
                                monitoring and neurorehabilitation for multiple
                                sclerosis. We thank the center's director and
                                clinical team for their incredible hospitality.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default January2023;
