import { Carousel } from "react-bootstrap"
import "./ComponentHero.scss"

const ComponentHero = () => {
    return (
        <>
            <section className="hero d-flex align-items-center scroll">
                <div className="container text-center text-white">
                    <div className="mb-5 pb-0 mb-md-0 pb-md-0" >
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <h1 className="mb-3 hero-text" > <span className="display-3 mb-3"> Myelin-H</span><br /><span className="h3 " >

                                </span></h1>
                                <p className="text-white font-weight-bold font-26 mt-4 mb-4">
                                    Neurotechnology Platform for Universal Brain Health 
                                </p>
                            </div>
                        </div>
                        <Carousel interval={1800} fade={false} controls={false} indicators={false} >
                            <Carousel.Item>
                                <h1 className="text-white" >
                                Monitoring and Repair
                                </h1>
                            </Carousel.Item>
                            <Carousel.Item>
                                <h1 className="text-white" >
                                Monitoring and Repair
                                </h1>
                            </Carousel.Item>
                        </Carousel>
                        <div className="row mt-4">
                            <div className="col-md-10 offset-md-1">

                                <p className="text-white font-300 font-22 mt-4">
                                Myelin-H is revolutionising <span className="font-bold">brain health monitoring and recovery</span>, extending from multiple sclerosis to all brain disorders.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="scroll-container">
                        <div id="scroll-btn">
                            <a href="#solution"><span></span><span></span><span></span></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComponentHero;