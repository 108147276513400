
const ScienceTechHero = () => {
    return (
        <>
            <section className=" contact-hero">
                <div className="container my-0 py-4 py-md-5">
                    <div className="text-container text-center text-white py-3 ">

                        <h1 className="font-weight-bold display-4" >Science & Technology</h1>
                        {/* <p className="font-22">Need to get in touch with the team? We are all ears.</p> */}
                        {/* <a href="info@myelinh.com" className="mt-3 mb-3 btn btn-dark custom-round">Contact Us.</a> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ScienceTechHero;