import { useState } from 'react';
import {
    FaLinkedinIn,
    FaYoutube,
    FaTwitter,
    FaGlobeAmericas,
} from 'react-icons/fa'
import { GoRocket } from "react-icons/go"
import { FcGoogle } from "react-icons/fc"
import { AiFillRocket } from "react-icons/ai"
import "./ProfileCard.scss"
import { Modal, Button } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'
import { myelinersCollectionRef } from '../../../firebase/fire-app';

const IconFinder = ({ icon }) => {
    if (icon == "google") {
        return <FcGoogle />
    } else if (icon == "web") {
        return <FaGlobeAmericas />
    } else if (icon == "nasa") {
        return <GoRocket />
    } else {
        return ""
    }
}

const ProfileCard = ({ col, info, type }) => {
    const { name, img, designation, linkedin, twitter, youtube, web, designation2 } = info;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updateProfile =()=>{
        myelinersCollectionRef.doc(info.officeEmail).update({
            references: info.references,
            publicDescription: info.shortDescription
        })
    }
    return (
        <>
            <div className={" col-md-6 my-3"}>
                <div className="profile-card bg-white">
                    <div className="row d-flex align-items-center">
                        <div className="col-5">
                            <img src={img} alt={name} className="img-fluid" />
                            <div className="mt-2 d-block d-md-none">
                                <button onClick={handleShow} className="btn-primary btn btn-sm">
                                    Read bio...
                                </button>
                            </div>
                        </div>
                        <div className="col-7 d-flex align-items-center py-3 py-md-0">
                            <div className="text-left">
                                <h5 className='text-primary font-bold'>{name} </h5>
                                {type === "leadership" ?"":<p>{info.designation} </p>}
                                
                                {designation2 && <p>{designation2} </p>}
{/* <button onClick={updateProfile} className="btn btn-sm btn-danger">{info.officeEmail}</button> */}
                                <div className="text-left mt-md-3">
                                    {
                                        linkedin && <SocialLink link={linkedin} icon={<FaLinkedinIn />} />
                                    }
                                    {
                                        twitter && <SocialLink link={twitter} icon={<FaTwitter />} />
                                    }
                                    {
                                        youtube && <SocialLink link={youtube} icon={<FaYoutube />} />
                                    }
                                    {
                                        web && <SocialLink link={web} icon={<FaGlobeAmericas />} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-btn-container d-none d-md-block">
                    <button onClick={handleShow} className="btn-primary btn btn-sm">
                        Read bio...
                    </button>
                </div>

            </div>
            <Modal show={show} onHide={handleClose} centered className="p-4 profile-modal" size="lg" aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header >
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 offset-md-2 text-center text-md-left">
                            <img src={img} className="img-fluid" alt={name} />
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0 text-center text-md-left">
                            <h5>{name} </h5>
                            <p>{designation} </p>
                            {designation2 && <p>{designation2} </p>}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
    <div className="details-info p-0 p-md-4" dangerouslySetInnerHTML={{ __html: info.publicDescription }} ></div>
 
                    {/* {info.references &&
                        <div className="p-0 p-md-4">
                            <h4>References:</h4>
                            {info.references.map(item => <a href={item.link} target="_blank" className="btn btn-primary  my-2 mr-3">
                                <IconFinder icon={item.icon} /> &nbsp;   {item.text}
                            </a>)}
                        </div>} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProfileCard;

const SocialLink = ({ icon, link }) => {
    return (
        <a className="profile-contact-icon-container mr-2" href={link} target="_blank" rel="noopener noreferrer">
            <div className="profile-contact-icons">{icon} </div>
        </a>
    )
}



// <div className={" col-md-" + col}>
//                 <div className="profile-card  py-4">
//                     <div className=" mb-3">
//                         <img src={DPImg} alt="zied" className="img-fluid" />
//                     </div>
//                     <h5>Zied Tayeb</h5>
//                     <p>CEO & CO-FOUNDER</p>
//                     <div className="text-center mt-2">
//                         <a className="profile-contact-icon-container mx-1" href="www.fb.com" target="_blank" rel="noopener noreferrer">
//                             <div className="profile-contact-icons"><FaFacebookF /></div>
//                         </a>
//                         <a className="profile-contact-icon-container mx-1" href="www.fb.com" target="_blank" rel="noopener noreferrer">
//                             <div className="profile-contact-icons"><FaFacebookF /></div>
//                         </a>
//                         <a className="profile-contact-icon-container mx-1" href="www.fb.com" target="_blank" rel="noopener noreferrer">
//                             <div className="profile-contact-icons"><FaFacebookF /></div>
//                         </a>
//                     </div>
//                 </div>
//             </div>