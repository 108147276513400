import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import TargetBlankLink from "../../Components/TargetBlankLink/TargetBlankLink";
import { ZiedEmail } from "../../data/defaultData";


const ProductTwoScience = () => {
    return (
        <>
            <section className="py-4 contact-hero">
                <div className="container">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold h1">How it works</h1>
                    </div>
                </div>
            </section>
            <section className="bg-white py-5">
                <div className="container">
                    <p className="mb-3">
                        Myelin-H technology, which is a combination of wearable
                        sensors &amp; a biologically inspired machine learning
                        software engine, allows health professionals to remotely
                        (at-home)&nbsp;<strong>monitor</strong>
                        &nbsp;neurodegenerative&nbsp;
                        <strong>disease progression</strong>,&nbsp;
                        <strong>assess treatment</strong>/drug&nbsp;
                        <strong>effectiveness</strong>&nbsp;in real-time, adjust
                        medication doses remotely, and help perform precise
                        &amp; at-home therapeutic brain stimulations.
                        <br />
                        We are now primarily focusing on&nbsp;&nbsp;
                        <strong>Multiple Sclerosis,</strong>&nbsp;
                        <strong>Parkinson&rsquo;s</strong>&nbsp;
                        <strong>Disease, and Mental Disorders</strong>&nbsp;but
                        we are planning to scale our technology towards
                        diagnosing and monitoring various neurodegenerative
                        diseases.
                    </p>

                    <div className="row my-3">
                        <div className="col-md-8 offset-md-2">
                            <img
                                src={`/imgs/tech-image.png`}
                                alt=""
                                className="img-fluid"
                            />
                            <p className="text-center text-sm mt-4 font-weight-bold">
                                Figure:{" "}
                                <span className=""> How Myelin-H works?</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading">
                        Value Proposition
                    </h2>
                    <HorizontalLine center={true} className="mb-3" />
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className="list-group text-left text-md-center">
                                <li className="list-group-item">
                                    {" "}
                                    Help Develop Better Therapies.
                                </li>
                                <li className="list-group-item">
                                    {" "}
                                    Help Slow down Disease Progression.
                                </li>
                                <li className="list-group-item">
                                    {" "}
                                    Help provide tailored and under-need
                                    medications.
                                </li>
                                <li className="list-group-item">
                                    {" "}
                                    Help Perform Precise & At-Home Therapy.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-primary py-5 mb-5">
                <div className="container">
                    <h3 className="text-center text-white mb-4">
                        Would you like to collaborate with Myelin-H on one of
                        our clinical trials?
                    </h3>
                    <div className="row">
                        <div className="col-md-5 offset-md-1 text-center text-md-right mb-4 mb-md-0">
                            <TargetBlankLink
                                className="btn btn-dark mx-md-4"
                                link={ZiedEmail}
                                email={true}
                                text="Join Parkinson's clinical trials"
                            />
                        </div>
                        <div className="col-md-5 text-center text-md-left">
                            <TargetBlankLink
                                className="btn btn-dark mx-md-4"
                                link={ZiedEmail}
                                email={true}
                                text="Join Multiple Sclerosis clinical trials"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProductTwoScience;
