import React from "react";

const CompMiscelneousPolicies = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <h3>8. Technical Support:</h3>
                    <p>
                        You will receive support and maintenance, if applicable,
                        and a FAQs section on the service (MyelinFace) will be
                        available and provided with your Subscription.
                    </p>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <h3>9. Contact Us</h3>
                    <p>
                        If you have any questions or concerns regarding our
                        privacy policies, please send us a detailed message at{" "}
                        <a href="mailto:legal@myelinh.com">legal@myelinh.com</a>{" "}
                        or at the mailing address below. <br />
                        Myelin-H SARL. Attn: Legal Department 9 Av. des
                        Hauts-Fourneaux, 4362 Esch-Belval Esch-sur-Alzette,
                        Luxembourg
                    </p>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <h3>10. Privacy Notice for European Residents</h3>
                    <p>
                        If you are a resident of the European Economic Area, the
                        United Kingdom, or Switzerland (collectively, “Europe”),
                        you may have additional rights under the General Data
                        Protection Regulation (the “GDPR”) or other European
                        data protection legislation.
                    </p>
                    <ul>
                        <li>
                            Legal Bases for Processing: The processing of your
                            Personal Data is contingent upon having a lawful
                            basis for doing so. These bases include consent,
                            contractual necessity, and our legitimate interests
                            or the legitimate interests of others. The specific
                            legal bases we typically rely on for each category
                            of processing activity are outlined as follows:
                        </li>
                        <li>
                            Service delivery: Processing is necessary to fulfil
                            our contractual obligations or to undertake actions
                            that you request prior to engaging our Services. In
                            instances where we cannot process your Personal Data
                            as required for operating the Services based on
                            contractual necessity, we do so based on our
                            legitimate interest in providing you with the
                            products or Services you access and request.
                        </li>
                        <li>
                            Research and development: These activities are
                            conducted based on our legitimate interests.
                        </li>
                        <li>
                            Marketing and advertising: Processing is based on
                            your consent where required by applicable law. If
                            consent is not mandated by applicable law, we
                            process your personal information for these purposes
                            based on our legitimate interests in promoting our
                            business.
                        </li>
                        <li>
                            Compliance and protection: From time to time,
                            processing of Personal Data may be necessary to
                            comply with legal obligations, protect vital
                            interests, or carry out tasks in the public
                            interest.
                        </li>
                        <li>
                            We reserve the right to use your Personal Data for
                            purposes not described in this Privacy Policy where
                            permitted by law and where the reason aligns with
                            the purpose for which we collected it. If we need to
                            utilise your Personal Data for an unrelated purpose,
                            we will notify you and explain the applicable legal
                            basis.
                        </li>
                        <li>
                            Retention: The appropriate retention period for your
                            Personal Data is determined by considering various
                            factors, including the amount, nature, and
                            sensitivity of the information, potential risks
                            associated with unauthorised use or disclosure,
                            purposes of processing, alternative means to achieve
                            those purposes, and applicable legal requirements.
                        </li>
                        <li>
                            Data Subject Rights: You possess certain rights
                            regarding your Personal Data, including:
                        </li>
                        <li>
                            Access: You can request more information about the
                            Personal Data we hold about you and obtain a copy of
                            such data.
                        </li>
                        <li>
                            Rectification: If you believe any Personal Data we
                            hold about you is inaccurate or incomplete, you can
                            request correction or supplementation.
                        </li>
                        <li>
                            Erasure: You can request the deletion of some or all
                            of your Personal Data from our systems.
                        </li>
                        <li>
                            Withdrawal of consent: If we process your Personal
                            Data based on your consent, you have the right to
                            withdraw it at any time.
                        </li>
                        <li>
                            Portability: You can request a copy of your Personal
                            Data in a machine-readable format and, where
                            feasible, ask us to transmit it to another
                            controller.
                        </li>
                        <li>
                            Objection: You can object to the further use or
                            disclosure of your Personal Data for specific
                            purposes, such as direct marketing.
                        </li>
                        <li>
                            Restriction of processing: You can request that we
                            limit further processing of your Personal Data.
                        </li>
                        <li>
                            Right to file a complaint: You have the right to
                            lodge a complaint about our practices regarding your
                            Personal Data with the supervisory authority of your
                            country or European Economic Area Member State.
                        </li>
                    </ul>
                </div>
                <div className="text-center py-4">
                    <a
                        href="https://firebasestorage.googleapis.com/v0/b/ec-myelinh.appspot.com/o/all-docs%2Flz1iqdhi%20-%20BrainMirror-Terms-Service-Privacy-Policy-Myelin-H.pdf?alt=media&token=46069b8b-e200-40a9-9b67-4bbfddbf0346"
                        target="_blank"
                        className="btn btn-primary"
                        rel="noopener noreferrer"
                    >
                        Get the Privacy & Policy Document
                    </a>
                </div>
            </section>
        </>
    );
};

export default CompMiscelneousPolicies;
