import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./AboutOurStory.scss"
import 'react-h5-audio-player/lib/styles.css';


const AboutOurStory = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center h2 section-heading text-primary">Myelin-H Story</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className=" font-20 text-justify">
                        <p className="font-22 mb-3 text-center" >

                        Inspired by his mother&#39;s battle with <strong>multiple sclerosis</strong> and <strong>disability</strong>, Zied pursued a PhD in neuroscience and co-founded Myelin-H to pioneer <strong>personalised brain health and wellness.</strong>
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutOurStory;