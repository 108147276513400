import { useEffect } from "react";
import Newsletter from "../Home/Newsletter/Newsletter";
import ContactForm from "./ContactForm/ContactForm";
import ContactHero from "./ContactHero/ContactHero";
// import { firebaseAnalytics } from "../../firebase/fire-app";

const ViewContact = () => {
    document.title = "Myelin-H | Contact Us";
    // useEffect(() => {
    //     firebaseAnalytics.logEvent("contactpage_visited");
    // }, [])
    return (
        <>
            <main>
                <ContactHero />
                <ContactForm />
                <Newsletter />
            </main>
        </>
    );
};

export default ViewContact;