import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import MobileApp from "./img/mobile-app.png"
import AppUser from "./img/bci-sensor.png"
import DashboardImg from "./img/dashboard.jpeg"
import Dashboard1Img from "./img/dashboard-img.png"
import { BodyMirrorImg } from "../../Images";
import AppUserTwo from "./img/bci-sensor2.jpeg"

const ComponentSoftwares = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="h2 text-center section-heading">
                        Software
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 mb-5 mb-md-0">
                            <div className="app-ui-container py-5 bg-white">
                                <h4 className="font-weight-bold text-center">BrainMirror Mobile App</h4>
                                <div className="px-5">
                                <img src={MobileApp} alt="" className="img-fluid"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-7 offset-md-1 text-center">
                        <h3 className="h4 mb-3 font-weight-bold">Mobile App for Biosignals Encoding & Streaming</h3>
                            {/* <p>Portable and wireless dry brain sensors with 32 channels. The sensor is <b>CE-certified and FDA cleared wearable</b>.</p> */}
                        <div className="row mt-4">
                            <div className="col-md-6 mb-4 mb-md-0">
                            <img src={AppUser} alt="" className="img-fluid"/>
                            </div>
                            <div className="col-md-6">
                            <img src={AppUserTwo} alt="" className="img-fluid"/>
                            </div>
                        </div>
                        </div>
                    </div>

                    
                </div>
            </section>
            {/* brain mirror */}
            <section className="py-5">
                <div className="container">
                <h2 className="h2 text-center section-heading mt-4">BodyMirror Machine Learning Software</h2>
                    <HorizontalLine center={true}/>
                    <div className="row mt-4">
                        <div className="col-md-6 offset-md-3">
                                <img src={BodyMirrorImg} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-md-8 offset-md-2">
                        <h3 className="h4 mt-4 text-center font-weight-bold">Brain-Computer Interface (BCI) software engine to decode human thoughts into medical solutions.</h3>

                        </div>
                    </div>
                    
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                <h2 className="text-center h2 section-heading mt-4">BrainMirror Clinical Dashboard</h2>
                    <HorizontalLine center={true}/>
                    <div className="row mt-4">
                        <div className="col-md-6 mb-4 mb-md-0">
                            <img src={DashboardImg} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-md-6">
                            <img src={Dashboard1Img} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-md-8 offset-md-2 text-center">
                            <h4 className="font-weight-bold mt-4">
                            Clinical Dashboard for Health Professionals. Doctors can adjust medication doses and perform remote monitoring of brain diseases regardless of the patient's location.
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ComponentSoftwares;