import React from "react";
import { MdOutlineClose } from "react-icons/md";

const CompOnScreenSpotifyPlayer = ({showPlayer, setShowPlayer, activeEpisode}) => {
    return (
        <>
           {showPlayer && <div className="onscreen-spotify container-fluid">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8 iframe-container">
                        <span onClick={()=> setShowPlayer(false)} className="close ">
<MdOutlineClose/>
                        </span>
                        <iframe
                            src={activeEpisode}
                            height="auto"
                            width="100%"
                            frameborder="0"
                            scrolling="no"
                            
                        ></iframe>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default CompOnScreenSpotifyPlayer;
