import React from 'react';

const ViewWearableDevices = () => {
    return (
        <>
            <section className="py-5 contact-hero">
                <div className="container py-1 py-md-5">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold h1" >
                            Wearable Devices
                        </h1>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <h4 className='text-center mb-5'>
                                Overview On Wearable Devices
                            </h4>
                            {/* <YouTubeResponsiveEmbed youTubeId={"kFYDgvWB2lo"} /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewWearableDevices;