import AccordionComponent from "../../Components/AccordionComponent/AccordionComponent";
import { faqData } from "./faqData";

const FrequentlyAskedQuestions = () => {
    return (
        <>
            <section className="py-5 contact-hero">
                <div className="container">
                    <div className="text-container text-center text-white py-3 ">

                        <h1 className="font-weight-bold h1" >
                            Frequently Asked Questions
                    </h1>
                    </div>
                </div>
            </section>
            <section className=" py-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            {
                                faqData.map(item => <AccordionComponent key={item.key} className="mb-3" data={item}/> )
                            }
                            
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default FrequentlyAskedQuestions;

