import React from "react";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./LatestNews.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const April2024 = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                                Myelin-H Presentation to His Majesty the King of
                                Belgium and His Royal Highness the Grand Duke of
                                Luxembourg
                            </h2>
                            <p className="font-16 text-justify">
                                Myelin-H was honoured and thrilled to have met
                                and presented its Neurotech to{" "}
                                <b>His Majesty the King of Belgium</b>,{" "}
                                <b>
                                    His Royal Highness the Grand Duke of
                                    Luxembourg
                                </b>
                                , along with Xavier Bettel, Minister for Foreign
                                Affairs of Luxembourg, Hadja Lahbib, Minister
                                for Foreign Affairs of Belgium, Yuriko Backes,
                                Minister of Defence of Luxembourg, Lex Delles,
                                Minister of the Economy of Luxembourg,
                                Jean-Louis Thill, the Ambassador of Luxembourg
                                to Belgium, and other distinguished guests and
                                political leaders. It was an absolute privilege
                                and pleasure to have had the opportunity to
                                present our brain-computer interface technology,
                                our Luxembourg-based multi-site clinical trial,
                                as well as our business plan to make Myelin-H's
                                neurotechnology available to over a billion
                                patients suffering from brain disorders, helping
                                them halt and combat their brain illness.
                            </p>
                        </div>

                        <div className="col-md-3">
                            <div className="img-card">
                                <img
                                    src="/latest-news/04.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="img-card">
                                <img
                                    src="/latest-news/03.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-card">
                                <img
                                    src="/latest-news/02.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 2 */}
            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                                A Special Guest (Dr Christoph Guger) and a
                                Brain-Computer Interface Hero on the MyelinPub!
                                🧠🎙
                            </h2>
                            <p className="font-16 text-justify">
                                We are delighted to reveal that MyelinPub's
                                second guest is Dr Christoph Guger, the founder
                                of g.tec medical engineering and one of the
                                pioneers and godfathers of brain-computer
                                interfaces (BCI). Dr Christoph Guger breathes
                                and eats BCI & Neurotech for breakfast. He has
                                been at the forefront of this field for over 25
                                years, founding his BCI company when the
                                research landscape was in its infancy. The
                                collaboration between his company and Myelin-H
                                spans several fruitful years, and we are excited
                                to announce forthcoming joint projects. In our
                                discussion with Dr Guger, we explored recent
                                advancements in BCI technology, including
                                Myelin-H's groundbreaking work on multiple
                                sclerosis and upcoming clinical trials and Dr
                                Guger's insights into Neuralink's recent
                                invasive chess demo with a patient. It was also
                                a fabulous opportunity to uncover the individual
                                behind the BCI pioneer. 🧠🎙
                            </p>
                            <p>
                                Don't miss out on the release of the full
                                MyelinPub episode next week ☕️🎉!
                            </p>
                            <p>
                                To watch the full video:{" "}
                                <a
                                    href="https://youtu.be/kPQmysUUmIA"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://youtu.be/kPQmysUUmIA
                                </a>
                            </p>
                        </div>

                        <div className="col-md-5">
                            <div className="img-card">
                                <img
                                    src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/7067184e-018d-7873-da65-6b1dc0468d78.png"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* news 3 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                                🧠 Breaking News: Introducing Myelin-H's
                                Neuro-Chess: A Brain-Controlled Chess Game for
                                Patients with Brain Diseases 🎮
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <p className="font-16 text-justify">
                                Imagine you can play chess and move chess pieces
                                using your thoughts and your brain signals.
                                Unlike Neuralink's recent groundbreaking
                                demonstration, you can do that with no surgical
                                operations, non-invasively and by wearing visors
                                and sailor captain hats (stylish brain sensors),
                                and you know what? The mind-controlled chess
                                game will also help you remotely assess your
                                brain health status, enhance your cognitive
                                abilities, and combat your brain illness.
                                Already Intrigued? Go nowhere but watch this
                                demo video of our recent technological
                                development en route to building a digital
                                neurotherapeutics platform for Universal Brain
                                Health Monitoring and Recovery.
                            </p>
                        </div>

                        <div className="col-md-6">
                            <div className="img-card">
                                <a
                                    href="https://youtu.be/AoF1Zf-EHuA?si=abCCFOV9mpXIQvOY"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/d5c154bd-0f96-d04d-bfa0-6a17424c82ea.png"
                                        alt=""
                                        className=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* news 4 */}
            <section className="py-5 ">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                                Press Release: Myelin-H Co-Founder Samaher
                                Garbaya: WOMEN & TECH <br /> “I've always been a tech
                                girl with a mathematical mind”
                            </h2>
                        </div>
                        <div className="col-md-6">
                            <div className="img-card">
                                <a
                                    href="https://www.startupluxembourg.com/news-and-insights/ive-always-been-a-tech-girl-with-a-mathematical-mind?utm_content=289630254&utm_medium=social&utm_source=linkedin&hss_channel=lcp-35619067"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/b17af239-f3f0-7f8c-9235-9d97422661cd.png"
                                        alt=""
                                        className=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="font-16 text-justify">
                                Press Release: Myelin-H Co-Founder Samaher
                                Garbaya Champions Women in Tech: "I've always
                                been a tech girl with a mathematical mind." Our
                                co-founder's inspiring journey underscores the
                                crucial role of women in driving technological
                                innovation. 🌟👩‍💻
                            </p>

                            <p className="mt-2">
                                Read the full article:{" "}
                                <a
                                    href="https://www.startupluxembourg.com/news-and-insights/ive-always-been-a-tech-girl-with-a-mathematical-mind?utm_content=289630254&utm_medium=social&utm_source=linkedin&hss_channel=lcp-35619067"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    StartupLuxembourg.com
                                </a>
                            </p>
                        </div>

                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default April2024;
