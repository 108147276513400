import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import HorizontalLine from '../../../Components/common/HorizontalLine/HorizontalLine';

// import "./ThereIsMore.scss";

const ThereIsMore = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const demoImg = "/brain-technology.jpg"
    return (
        <>
            <section className="py-5 ">
                <div className="container">
                    <h2 className="h2 text-center section-heading mb-4">There is more...</h2>
                    {/* <HorizontalLine center={true} className="mb-3" /> */}
                    <div className="row">
                        <div className="col-md-12 offset-md-0 px-md-5">
                            <Carousel activeIndex={index} onSelect={handleSelect} slide={true} pause="hover" interval="5000" controls={false} indicators={true}>
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-md-6">
                                                <div class="there-is-more py-4">
                                                    <h3 className='font-bold'>Cognitive Games</h3>
                                                    <blockquote className="text-justify">
                                                    Each game in the manual is based upon the state-of-the art paradigm for capturing a specific biomarker. Both versions are available for playing.

                                                    </blockquote>
                                                    <Link to="/cognitive-games" className="btn btn-primary">Learn More</Link>

                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                    <img src="/more/cognitive-games.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-md-6">
                                                <div class="there-is-more py-4">
                                                    <h3 className='font-bold'>Wearable Devices</h3>
                                                    <blockquote className="text-justify">
                                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat fugiat quidem debitis rem? Eius assumenda quis nam?
                                                        Maxime laborum quae, veritatis error, ipsum commodi odit.
                                                    </blockquote>
                                                    <Link to="/wearable-devices" className="btn btn-primary">Learn More</Link>

                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                    <img src="/more/devices.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                           
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-md-6">
                                                <div class="there-is-more py-4">
                                                    <h3 className='font-bold'>Cognitive Games</h3>
                                                    <blockquote className="text-justify">
                                                        Each game in the manual is based upon the state-of-the art paradigm for capturing a specific biomarker. Both versions are available for playing.
                                                    </blockquote>
                                                    <Link to="/cognitive-games" className="btn btn-primary">Learn More</Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                <a rel="noopener noreferrer" target="_blank" href="https://nyulangone.org/">
                                                    <img src="/more/cognitive-games2.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-md-6">
                                                <div class="there-is-more py-4">
                                                    <h3 className='font-bold'>TMS-EEG Analysis</h3>
                                                    <blockquote className="text-justify">
                                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat fugiat quidem debitis rem? Eius assumenda quis nam?
                                                        Maxime laborum quae, veritatis error, ipsum commodi odit.
                                                    </blockquote>
                                                    <Link to="/tms-eeg-analysis" className="btn btn-primary">Learn More</Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-4">
                                                    <img src="/more/eeg.png" alt="" className="img-fluid mb-0 mb-md-5" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                            </Carousel>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default ThereIsMore;