import React from "react";
import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const TechBodyMirror = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <section id="solution" className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="h2 text-primary text-center section-heading">
                                BodyMirror Processing Software
                            </h2>
                            <HorizontalLine center="true" className="mb-3" />
                            <p className="mb-3 font-22 text-center">
                                Our biologically inspired processing software
                                translates your data while playing our games
                                into a <strong>comprehensive</strong>{" "}
                                <strong>view</strong> of your brain and body
                                health. It goes beyond that by providing{" "}
                                <strong>personalised</strong> recommendations,
                                expert coaching, and profound{" "}
                                <strong>insights</strong> into{" "}
                                <strong>brain disease management</strong>.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img
                                src="/solutions/bodymirror-full.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>

                    {/* 
                    <Carousel activeIndex={index} onSelect={handleSelect} slide={true} pause="hover" interval="1500" controls={false} indicators={true}>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/1.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/2.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/3.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/4.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/3.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/5.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/6.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/bodymirror/7.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                       
                        

                    </Carousel> */}
                </div>
            </section>
        </>
    );
};

export default TechBodyMirror;
