import ComponentFooter from "../common/Footer/ComponentFooter"
import ComponentNavbar from "../common/Navbar/ComponentNavbar"
import CompSocialLinks from "./CompSocialLinks";

const PageLayout = ({ children }) => {
    return (
        <>
            <ComponentNavbar />
            <CompSocialLinks/>
            <main className="page-content-section">
            {children}
            </main>
           
            <ComponentFooter />
        </>
    );
};

export default PageLayout;