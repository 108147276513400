"use client"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BrandLogo } from "../../../Images";
import "./styles.scss"


const ComponentNavbar = () => {
    return (
        <>
            <Navbar collapseOnSelect sticky="top" className="glass-navbar" expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img className="site-logo" src={BrandLogo} alt="" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="myelinh-navbar" />
                    <Navbar.Collapse id="myelinh-navbar text-center">
                        <Nav style={{ color: "#ffffff" }} className="text-center ml-auto">
                            <Nav.Item>
                                <Nav.Link as={Link} to="/" eventKey="1" className="mr-md-2 text-light">
                                    Home
                                </Nav.Link>
                            </Nav.Item>
                            {/* <NavDropdown title="Technology" className="mr-md-4">
                                <NavDropdown.Item eventKey="6">
                                    <Link to="/product/BrainMirror">Science</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="7">
                                    <Link to="/product/how-it-works">How it works</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item eventKey="8">
                                    <Link to="/frequently-asked-questions">FAQ</Link>
                                </NavDropdown.Item>
                            </NavDropdown> */}
                            <Nav.Item>
                                <Nav.Link as={Link} to="/about-us" eventKey="2" className="mr-md-2 text-light">
                                    About Us
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/science-and-technology" eventKey="2" className="mr-md-2 text-light">
                                    Science & Technology
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/news" eventKey="2" className="mr-md-2 text-light">
                                    News
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/blog" eventKey="2" className="mr-md-2 text-light">
                                    Blog
                                </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link as={Link} to="/Myelin-H-Neurotech-Bar" eventKey="3" className="mr-md-4 text-light">
                                    Myelin-H Neurotech Bar
                                </Nav.Link>
                            </Nav.Item> */}

                            <Nav.Item>
                                <Nav.Link as={Link} to="/myelin-pub" eventKey="4" className="mr-md-2 text-light">
                                    The Myelin Pub
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/careers" eventKey="4" className="mr-md-2 text-light">
                                    Careers
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/contact" eventKey="5" className="text-light">
                                    Contact
                                </Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default ComponentNavbar;