import React from 'react';
import HorizontalLine from '../../Components/common/HorizontalLine/HorizontalLine';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
const TechClinicalDashboard = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <section id="solution" className="py-5">
                <div className="container">
                    <h2 className="h2 text-primary text-center section-heading">MyelinBoard</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <p className="mb-3 font-bld font-22 text-left">
                    MyelinBoard is a <strong>one-stop shop</strong> for individuals with brain disorders and their health professionals to access data, scores, recommendations, personalised insights, coaching, and <strong>share</strong> results <strong>anytime</strong> and <strong>anywhere</strong>.
                    </p>

{/* 
                    <Carousel activeIndex={index} onSelect={handleSelect} slide={true} pause="hover" interval="1500" controls={false} indicators={true}>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/dashboard/1.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/dashboard/2.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
            
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/dashboard/3.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-md-7 ">
                                        <img src="/tech/dashboard/4.png" alt="" className="img-fluid mb-0 mb-md-5" />

                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                       
                        

                    </Carousel> */}
                </div>
            </section>
        </>
    );
};

export default TechClinicalDashboard;


