import React from "react";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./LatestNews.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const LatestNews = () => {
    return (
        <>
            <section className="py-5 ">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">
                        Latest News
                    </h2>
                    <HorizontalLine center={true} className="mb-1" />
                    <div className="row justify-content-center">
                        <div className="col-md-12 mb-3">
                            <p className="font-16 text-justify">
                            Myelin-H was honoured and thrilled to have met and presented its Neurotech to <b>His Majesty the King of Belgium</b>, <b>His Royal Highness the Grand Duke of Luxembourg</b>, along with Xavier Bettel, Minister for Foreign Affairs of Luxembourg, Hadja Lahbib, Minister for Foreign Affairs of Belgium, Yuriko Backes, Minister of Defence of Luxembourg, Lex Delles, Minister of the Economy of Luxembourg, Jean-Louis Thill, the Ambassador of Luxembourg to Belgium, and other distinguished guests and political leaders. It was an absolute privilege and pleasure to have had the opportunity to present our brain-computer interface technology, our Luxembourg-based multi-site clinical trial, as well as our business plan to make Myelin-H's neurotechnology available to over a billion patients suffering from brain disorders, helping them halt and combat their brain illness.                
                            
                                        </p>
                        </div>
                
                        <div className="col-md-3">
                            <div className="img-card">
                                <img
                                    src="/latest-news/04.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="img-card">
                                <img
                                    src="/latest-news/03.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-card">
                                <img
                                    src="/latest-news/02.jpeg"
                                    alt=""
                                    className=""
                                />
                            </div>
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <Link to="/news" className="btn btn-primary">Check  out all news</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LatestNews;
