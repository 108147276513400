export const faqData =[
    {
        key: 1,
        qus: "What are Myelin-H products?",
        ans: "Myelin-H offers 2 products: Product 1 is for hospitals and clinics. Product 2 is for biopharmaceutical companies."
    },
    // {
    //     key: 2,
    //     qus: "How can I use Myelin-H services?",
    //     ans: `Everything is done remotely. We come to your place wherever/whenever you are. You can think of it as an "Uber" to diagnose and monitor your health status. Just use our free app to book an appointment. Myelin-H, we stand by you at everything step you make.`
    // },
    {
        key: 3,
        qus: `Who can use it?`,
        ans: `Brain disease patients (Parkinson and Multiple Sclerosis).`
    },
    {
        key: 4,
        qus: `Is Myelin-H designed for patients or for health professionals?`,
        ans: `Myelin-H offers its products to both patients and hospitals/clinics.        `
    },
    {
        key: 5,
        qus: `When will I see results?`,
        ans: `Your doctor will typically receive the results in a few minutes following the recording session, while you can check out the results using Myelin-H app as soon as your doctor approves our medical report.        `
    },
    {
        key: 6,
        qus: `How much will you charge me for using the app?`,
        ans: `Our app is free of charge. We charge annually for using our services. If your hospital/doctor has already access to our technology, you can freely use all our services.`
    }
]