import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { blogsCollectionRef } from "../../firebase/fire-app";
import PostCard from "./PostCard";
// import "./ck.css"
import "./blog.scss";

const ViewBlog = () => {
    const [allBlogs, loading, error] = useCollectionData(blogsCollectionRef, {
        idField: "id",
    });
    return (
        <>
            <section className=" contact-hero">
                <div className="container my-0 py-4 py-md-4">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold display-4">
                            Myelin-H Blog
                        </h1>
                        {/* <p className="font-22">Need to get in touch with the team? We are all ears.</p> */}
                        {/* <a href="info@myelinh.com" className="mt-3 mb-3 btn btn-dark custom-round">Contact Us.</a> */}
                    </div>
                </div>
            </section>
            <section className="py-4 bg-light">
                <div className="container">
                    <div className="row">
                        {allBlogs &&
                            allBlogs.length > 0 &&
                            allBlogs.map((item) => (
                                <PostCard col="6" blog={item} key={item.id} />
                            ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewBlog;
