import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { blogsCollectionRef } from '../../firebase/fire-app';
import SectionLoading from '../../Components/SectionLoading';
import TemplateSingle from './TemplateSingle';
import { timeStampToDateViaSecond } from '../../Utilities/convertTime';

const ViewBlogSinglePage = () => {
    const {slug} = useParams()
    const [blogData, setBlogData] = useState(null)

    const getD = async () => {
        const blogRow = await blogsCollectionRef.doc(slug).get();
        const dddd = await { id: blogRow.id, ...blogRow.data() }
        setBlogData(dddd)
        // snapshot.forEach(doc => {
        //     const bl = { id: doc.id, ...doc.data() }
        //     if (bl.slug === slug) {
        //         console.log(bl)
        //     }
        // });
    }
    useEffect(()=>{
        getD()
    },[])
    return (
        <>
      {blogData &&      <section className=" contact-hero">
                <div className="container my-0 py-4 py-md-4">
                    <div className="text-container text-center text-white py-2 ">
                        <h1 className="font-weight-bold  mb-0">
                           {blogData.title} 
                        </h1>
                        <p className="mb-0 text-white font-italic">
                            {timeStampToDateViaSecond(blogData.createdAt)}
                        </p>
                    </div>
                </div>
            </section>}
            {blogData? <TemplateSingle blog={blogData}/>:<SectionLoading/> }
        </>
    );
};

export default ViewBlogSinglePage;