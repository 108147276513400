import { Route, Switch } from 'react-router-dom';
import PageLayout from '../Components/layouts/PageLayout';
import ViewAbout from './AboutUs/ViewAbout';
import BCI4MedicineBar from './BCI4MedicineBar/BCI4MedicineBar';
import ViewCareers from './Careers/ViewCareers';
import ViewContact from './Contact/ViewContact';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import ViewHome from './Home/ViewHome';
import ViewTest from './Home/ViewTest';
import ViewNews from './News/ViewNews';
import ProductOneBrainMirror from './ProductOneBrainMirror/ProductOneBrainMirror';
import ProductTwoScience from './ProductTwoScience/ProductTwoScience';
import ViewCognitiveGames from './ThereIsMore/ViewCognitiveGames';
import ViewWearableDevices from './ThereIsMore/ViewWearableDevices';
import ViewScienceTechnology from './ViewScienceTechnology/ViewScienceTechnology';
import ViewNeurotechPub from './ViewNeurotechPub/ViewNeurotechPub';
import ViewBlog from './ViewBlog.js/ViewBlog';
import ViewBlogSinglePage from './ViewBlog.js/ViewBlogSinglePage';
import ViewPrivacyPolicy from './ViewPrivacyPolicy/ViewPrivacyPolicy';

const Main = () => {
    return (
        <>
            <PageLayout>
                <Switch>
                    <Route exact path="/">
                        <ViewHome />
                    </Route>
                    <Route exact path="/home">
                        <ViewHome />
                    </Route>
                    <Route exact path="/test">
                        <ViewTest />
                    </Route>
                    <Route exact path="/news">
                        <ViewNews />
                    </Route>
                    <Route exact path="/about-us">
                        <ViewAbout />
                    </Route>
                    <Route exact path="/science-and-technology">
                        <ViewScienceTechnology />
                    </Route>
                    <Route exact path="/Myelin-H-Neurotech-Bar">
                        <BCI4MedicineBar />
                    </Route>
                    <Route exact path="/contact">
                        <ViewContact />
                    </Route>
                    <Route exact path="/careers">
                        <ViewCareers />
                    </Route>
                    <Route exact path="/blog">
                        <ViewBlog />
                    </Route>
                    <Route exact path="/blog/:slug">
                        <ViewBlogSinglePage />
                    </Route>
                    <Route exact path="/myelin-pub">
                        <ViewNeurotechPub />
                    </Route>
                    <Route exact path="/product/BrainMirror">
                        <ProductOneBrainMirror />
                    </Route>
                    <Route exact path="/product/how-it-works">
                        <ProductTwoScience />
                    </Route>
                    <Route exact path="/frequently-asked-questions">
                        <FrequentlyAskedQuestions />
                    </Route>
                    <Route exact path="/cognitive-games">
                        <ViewCognitiveGames />
                    </Route>
                    <Route exact path="/wearable-devices">
                        <ViewWearableDevices />
                    </Route>
                    <Route exact path="/privacy-policy">
                    <ViewPrivacyPolicy />
                    </Route>
                </Switch>
            </PageLayout>
        </>
    );
};

export default Main;