import React from "react";
import { MdOutlinePlayCircle } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { FaSpotify } from "react-icons/fa";
import YouTubeResponsiveEmbed from "../../Components/YouTubeResponsiveEmbed";

const CompPodcastCard = ({ setActiveEpisode, setShowPlayer, data }) => {
    const onActiveEpisodeChange = () => {
        setActiveEpisode(data.spotifyURL);
        setShowPlayer(true);
    };

    return (
        <>
            <div className="podcast-card px-4 py-4 bg-">
                <button className="btn mb-3 btn-primary btn-round">
                    EPISODE {data.episode}
                </button>
                <h4 className="font-bold mb-4">
                    {data.title}
                </h4>
                <p className="font-16">{data.dateTime}</p>

                <p className="mt-3 font-bold">Guests:</p>
                <p className="font-16 mb-3">{data.guestName}</p>

                <div className="d-flex align-items-center">
                    Listen:{" "}
                    <span
                        onClick={onActiveEpisodeChange}
                        className="mx-3 spotify-play"
                    >
                        <FaSpotify />
                    </span>
                    <a href="#youtube-play" className="youtube-play">
                        <FaYoutube />
                    </a>
                </div>
                {/* <Spotify link="https://open.spotify.com/show/4mtZMYKndSGA5TfEZci9AY"/>
                      <div className="px-2">
                        <h5>Episode - 01</h5>
                      </div> */}
            </div>

        </>
    );
};

export default CompPodcastCard;
