import React from "react";

const December2023 = () => {
    return (
        <>
            {/* <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="font-bold text-center mb-1">
                            Planned Visit & Collaboration with the University of Cambridge
                            </h2>

                            <p>
                            We are excited to share that Myelin-H will be visiting and discussing this month a new collaboration with one of the world's best and most esteemed multiple sclerosis clinical centres in the world: the University of Cambridge Multiple Sclerosis Clinical Center. We will explore leveraging our neurotechnology and brain-machine interface for ongoing clinical trials promoting remyelination in MS patients.


                            </p>
                            <div className="text-center">
                                <a
                                    href={
                                        "https://www.linkedin.com/feed/update/urn:li:activity:7112801968158294016"
                                    }
                                    className="mt-4 btn btn-primary custom-round"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    VIEW DETAILS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 offset-md-1 mb-4">
                            <h2 className="font-bold  mb-1">
                                Planned Visit & Collaboration with the
                                University of Cambridge
                            </h2>

                            <p>
                                We are excited to share that Myelin-H will be
                                visiting and discussing this month a new
                                collaboration with one of the world's best and
                                most esteemed multiple sclerosis clinical
                                centres in the world: the University of
                                Cambridge Multiple Sclerosis Clinical Center. We
                                will explore leveraging our neurotechnology and
                                brain-machine interface for ongoing clinical
                                trials promoting remyelination in MS patients.
                            </p>
                        </div>
                        <div className="col-md-5">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/dab9abca-c597-5f14-459a-6966eeebaae5.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 offset-md-1">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/ea5682de-934c-54fc-3de0-1c4bcd747101.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h2 className="font-bold  mb-1">
                                Myelin-H's Visit to UCB Pharma Headquarters
                            </h2>

                            <p>
                                Exciting discussions at UCB Pharma's HQ in
                                Brussels! Myelin-H had the pleasure of
                                showcasing its groundbreaking neurotechnology
                                innovation during a visit to UCB Pharma's
                                headquarters in Brussels!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* batch 2 */}
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 offset-md-1 mb-4">
                            <h2 className="font-bold  mb-1">
                                Myelin-H @ Italian Embassy, Brussels
                            </h2>

                            <p>
                                Myelin-H was honoured to showcase its tech at
                                the Italian Ambassador's Residence in Brussels.
                                The event was attended by esteemed guests,
                                including Minister-President Jan Jambon and His
                                Excellency the Ambassador of Italy to Belgium.
                                Thanks to our partner, <b>imec.istart</b>, for this
                                incredible opportunity.
                            </p>
                        </div>
                        <div className="col-md-5">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/533e04a5-16c7-3895-556e-98d38b5bc89e.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 offset-md-1">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/39d6eaeb-a2dd-8dab-e58e-f33a13f8167a.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h2 className="font-bold  mb-1">
                                Myelin-H @ MedTech World Malta:
                            </h2>

                            <p>
                                Engaging discussions at Med-Tech World with
                                industry leaders, scientists, and our advisory
                                board member, Giovanni Lauricella. Exploring the
                                dynamic landscape of MedTech and its daily
                                intricacies.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* batch  */}
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 offset-md-1 mb-4">
                            <h2 className="font-bold  mb-1">
                            A Successful Pilot Study:
                            </h2>

                            <p>
                            We are happy to share that we have concluded an additional pilot study on our neurotechnology. Our cognitive games for remote monitoring and neurorehabilitation have shown promise! Interested in participating and having fun playing our cognitive games & getting your brain health assessed?

                            </p>
                            <p>Please email us at: <a href="mailto:info@myelinh.com">info@myelinh.com</a></p>

                        </div>
                        <div className="col-md-5">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/786225f7-7d73-5fd8-53b6-6e5747bc1ebb.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 offset-md-1">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/d3f52b7e-88e9-2bf7-7000-6b512877b9e4.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h2 className="font-bold  mb-1">
                            New MS Research Paper
                            </h2>

                            <p>Exciting news! 🎉 Check out our latest research paper, "Multiple Sclerosis in the Digital Health Age: Challenges and Opportunities," on medRxiv.


                            </p>
                            <p>Paper PDF: <a href="https://lnkd.in/eJUsgCZD " target="_blank" rel="noopener noreferrer">https://lnkd.in/eJUsgCZD </a></p>
                            <p>medRxiv Link: <a href="https://lnkd.in/efpBiCdM" target="_blank" rel="noopener noreferrer">https://lnkd.in/efpBiCdM</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-4  mb-4">
                            <h2 className="font-bold  mb-1">
                            Trade & Invest Luxembourg Highlights Myelin-H:
                            </h2>

                            <p>
                            Read the latest press article on Myelin-H's pioneering work in gamified neuroscience for brain disorders.

                            </p>
<p>
Here is the link: <a href="https://t.ly/G0zQf" target="_blank" rel="noopener noreferrer">https://t.ly/G0zQf</a>


</p>
                        </div>
                        <div className="col-md-3">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/de1782d0-2eba-9bc4-60af-a7afea481d9d.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default December2023;
