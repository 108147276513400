import "./BCIHero.scss"

const BCIHero = () => {
    return (
        <section className="py-5 contact-hero">
            <div className="container py-1 py-md-5">
                
                <div className="text-container text-center text-white py-3 ">
                    <h1 className="font-weight-bold h1" >
                    Welcome to the Myelin-H Neurotech Bar!
                    </h1>
                </div>
            </div>
        </section>
    );
};

export default BCIHero;