import React from 'react';
import SocialButtonsContainer from 'react-social-media-buttons';
import "./social-links.scss"

const CompSocialLinks = () => {
    return (
        <>
             <SocialButtonsContainer
                links={['https://www.linkedin.com/company/myelinh/','https://www.youtube.com/channel/UCPJzppsXHK7BZFixyv6AHvw','https://twitter.com/myelin__h',  'https://myelinh.medium.com/']}
                buttonStyle={{ margin: '0px 10px', backgroundColor: '#fff', borderRadius: '50%' }}
                iconStyle={{ color: '#51796F' }}
                openNewTab={true}
            />
        </>
    );
};

export default CompSocialLinks;