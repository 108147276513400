export const timeStampToDate = (timeStamp) => {
    // alert(timeStamp)
    if (timeStamp) {
        const milliseconds = timeStamp.milliseconds;
        const jsTime = new Date(milliseconds);
        const dateString = jsTime.toDateString();
        return dateString;
    }
};
export const defaultFormatDate = (timeStamp) => {
    // alert(timeStamp)
    if (timeStamp) {
        const milliseconds = timeStamp.seconds;
        const jsTime = new Date(milliseconds * 1000);
        const dateString = jsTime.toDateString();

        const yyyy = jsTime.getFullYear();
        let mm = jsTime.getMonth() + 1; // Months start at 0!
        let dd = jsTime.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = jsTime.toISOString().split("T")[0];
        console.log(formattedToday);
        return formattedToday;
    }
};
export const jsDateToDefaultFormatDate =(JSDate)=>{
    if (JSDate) {
        const yyyy = JSDate.getFullYear();
        let mm = JSDate.getMonth() + 1; // Months start at 0!
        let dd = JSDate.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = JSDate.toISOString().split("T")[0];
        console.log(formattedToday);
        return formattedToday;
    }
}

export const timeStampToDateViaSecond = (timeStamp) => {
    // alert(timeStamp)
    if (timeStamp) {
        const milliseconds = timeStamp.seconds * 1000;
        const jsTime = new Date(milliseconds);
        const dateString = jsTime.toDateString();
        return dateString;
    }
};

export const customDateFormat = (utcDate) => {
    if (utcDate) {
        let today = new Date(utcDate.milliseconds);
        var dd = today.getDate();

        var mm;
    }
};
