import React from 'react';
import { Image } from 'react-bootstrap';
import HorizontalLine from '../../../Components/common/HorizontalLine/HorizontalLine';
import PodcastSectionImg from "../img/myelinh-podcast.jpeg"

const PodcastSection = () => {
    return (
        <>
            <section className="bg-light pt-4 pb-5">
                <div className="container">
                <h2 className="h2 text-center section-heading">Monthly Podcast by Myelin-H</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row d-flex align-items-center ">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <p className="font-20">Myelin-H is organising a monthly podcast/webinar with some of the most knowledgeable speakers and scientists in neurotechnology and brain-computer interfaces for medicine. In this 2-hour bar experience, you will connect with the most influential people in the field, and get to know their research, papers, and cutting-edge projects.</p>
                        <br/>
                        <p className="font-20">Stay tuned for our next podcast/webinar.</p>
                    </div>
                    <div className="col-md-6">
                        <Image src={PodcastSectionImg} className="img-fluid" />
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PodcastSection;