const ourSoftwares = [
    {
        id: 1,
        title: "BrainMirror Product",
        name: "BrainMirror",
        description: `BrainMirror provides brain disorders patients, their physicians, and pharma with a big-picture look at brain &amp; body health. <strong>Disclaimer</strong>: BrainMirror is <strong>NOT</strong> a <strong>medical device </strong>for diagnosing disease or illness. It is not intended to be a substitute for professional medical advice and
        should not be relied on as such. <br/> Check the <a href="https://myelinh.com/privacy-policy" class="font-bold" target="_blank" rel="noopener noreferrer"><u>Privacy Policy</u></a> of BrainMirror.
`,
        img: "/images/softwares/brainMirror.png",
    },
    {
        id: 1,
        title: "R-MMS",
        name: "R-MMS",
        description:
            "<strong>R-MMS</strong> is a <strong>software-as-a-medical device</strong> for the <strong>remote monitoring </strong>of silent MS <strong>progression</strong> and <strong>treatment response</strong>. <br/><strong>Disclaimer</strong>: R-MMS Product will be classified as a <strong>medical device </strong> for <strong>MS</strong> home-based <strong>monitoring</strong>. ",
        img: "/images/softwares/mms.png",
    },
    // {
    //     id: 1,
    //     title: "Space Medicine Product",
    //     name: "SpaceMirror",
    //     description: "This product is designed for remote Monitoring of astronauts' brain activities in outer space and helping them enhance their cognitive abilities using neurofeedback.",
    //     img: "/images/softwares/spaceMirror.png",
    // },
];

export default ourSoftwares;
