import React from "react";

const CompHowWeCollectData = () => {
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h3>2. How We Collect Personal Data</h3>
                    <p>
                        We collect Personal Data about you from: <br />
                        Yourself, when you provide such information directly to
                        us, such as when completing your profile;
                        <br />
                        Myelin-H Wearable Sensors;
                        <br />
                  
                        Automatic data collection, such as Cookies, local
                        storage objects, web beacons, and other similar
                        technologies in connection with your use of the
                        Services; <br />
                        Customers and partners, such as hospitals, insurance
                        companies, pharma companies, health professionals, or
                        other organisations that engage with our Services;{" "}
                        <br />
                        Data providers, such as information services and data
                        licensors, when we supplement your data.
                    </p>
                </div>
            </section>
            <section className="bg-light py-5">
                <div className="container">
                <h3>3. Personal Data We Collect</h3>
                <p>We may collect the following types of Personal Data:</p>
                <ul>
                    <li>
                        Contact details, such as your first and last name,
                        email, and phone number;
                    </li>
                    <li>
                        Profile data, such as username and password that you may
                        establish to create an account on our interface
                        'MyelinFace', as well as any videos or photos when
                        playing our mood assessment game 'MindMaze game';
                    </li>
                    <li>
                        Communications that we exchange with you, including when
                        you contact us via MyelinFace (questions, feedback, or
                        reviews);
                    </li>
                    <li>
                        We may use certain of this information to
                        customise our games and experience with us as part of
                        our Services;
                    </li>
                    <li>
                        Device data, such as your computer or mobile device
                        operating system type and version, manufacturer and
                        model, browser type, screen resolution, RAM and disk
                        size, CPU usage, device type (e.g., phone, tablet), IP
                        Address, unique identifiers (including identifiers used
                        for advertising purposes), language settings, mobile
                        device carrier, radio/network information (e.g., Wi-Fi,
                        LTE, 3G), and general location information such as city,
                        state, or geographic area;
                    </li>
                </ul>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                <h3>4. How We Use Personal Data</h3>
                <p>
                    We utilise Personal Data to facilitate, enhance, comprehend,
                    and customise our Services. Personal Data is employed for
                    the following purposes:
                </p>
                <ul>
                    <li>Service delivery, encompassing:</li>
                    <li>
                        Providing, operating, enhancing, developing,
                        understanding, and personalising the Services and our
                        business, including testing, research, analysis, and
                        product development.
                    </li>
                    <li>
                        Fulfilling the purpose for which you provided the
                        information to us, including personalising and tailoring
                        our game-based monitoring and cognitive enhancement
                        programmes.
                    </li>

                    <li> Providing support and assistance for the Services.</li>
                    <li>
                        {" "}
                        Establishing and managing your account or other user
                        profiles.
                    </li>
                    <li> Adapting our interfaces based on your preferences.</li>
                    <li>
                        Sharing your data with your healthcare professional and
                        general practitioner
                    </li>
                    <li>
                        Training and enhancing our machine learning algorithms
                        which provide insights, recommendations, and overall
                        wellness score.
                    </li>
                    <li>
                        <strong>
                            We never sell our members’ personal data.
                        </strong>
                    </li>
                    <li>
                        <strong>Research and development</strong>. We may create
                        and use Aggregated Data, De-identified Data or other
                        anonymous data from Personal Data we collect, including
                        Wellness Data, for our business purpose, including to
                        analyse the effectiveness of the Services, improve and
                        add features to the Services, and analyse the general
                        behaviour and characteristics of users of the Services.
                        We also use anonymous Wellness Data for research
                        purposes to help us and our research partners answer
                        important questions about brain wellness and performance
                        and create tailored and personalised cognitive games for
                        wellness and cognitive enhancement for our users by
                        identifying cutting-edge insights and providing new
                        content and product features.
                    </li>
                    <li>
                        Compliance and protection, including: <br />
                        Protect against or deter fraudulent, illegal, or harmful
                        actions and maintain the safety, security, and integrity
                        of our Services; <br />
                        Comply with or enforce our legal or contractual
                        obligations, resolve disputes, and enforce our Terms of
                        Use and Terms of Sale; <br />
                        Audit our internal processes for compliance with legal
                        and contractual requirements and internal policies;{" "}
                        <br />
                        Protect our, your, or others’ rights, privacy, safety,
                        or property (including by making and defending legal
                        claims); and <br />
                        Respond to law enforcement requests and as required by
                        applicable law, court order, or governmental
                        regulations.
                    </li>
                </ul>
                </div>
            </section>
        </>
    );
};

export default CompHowWeCollectData;
