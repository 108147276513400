import React from 'react';
import YouTubeResponsiveEmbed from '../../Components/YouTubeResponsiveEmbed';

const ViewCognitiveGames = () => {
    return (
        <>
            <section className="py-5 contact-hero">
                <div className="container py-1 py-md-5">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold h1" >
                            Cognitive Games
                        </h1>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <h4 className='text-center mb-5'>
                                Each game in the manual is based upon the state-of-the art paradigm  for capturing a specific biomarker. Both versions are available for playing.
                            </h4>
                            <YouTubeResponsiveEmbed youTubeId={"pLwI9iBMSNQ"} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewCognitiveGames;