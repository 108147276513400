import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./Newsletter.scss"

const Newsletter = () => {
    return (
        <>
            <section className="py-5 newsletter bg-light">
                <div className="container">

                    <h2 className="text-center section-heading">Newsletter</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row">
                        <div className="col-md-10 mb-4 offset-md-1">
                            <p className="text-center font-20">
                                Be among the first to read about our cutting-edge technologies, special offers, sale events and customer surveys. Enjoy all this and more. Sign up for our newsletter now!
                            </p>
                        </div>
                        <div className="col-md-3 offset-md-3 mb-3 mb-md-0 ">
                            <input placeholder="Your name" type="text" className="form-control" />
                        </div>
                        <div className="col-md-3  ">
                            <input placeholder="Your email" type="text" className="form-control" />
                        </div>
                        <div className="col-12 text-center mt-3">
                            <button className="btn-primary  font-20 btn custom-round">Subscribe!</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Newsletter;