import React from 'react';

const NewsTemplateTwo = ({ data }) => {
    return (
        <>
            <article className="bg-light">
                <div className="container">
                    <div className="row  pt-4 justify-content-center d-flex align-items-center">
                        <div className="col-md-9">
                            <h2 className="font-bold mb-1">{data.title}</h2>
                            <p className="mb-3"><i>{data.date}</i></p>
                            <div dangerouslySetInnerHTML={{ __html: data.text }}></div>

                            <div className="text-center mb-5">
                                <a href={data.url} className='mt-4 btn btn-primary custom-round' target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6">                                <img src={data.img} alt="" className="img-fluid" />
</div>
                            </div>

                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default NewsTemplateTwo;