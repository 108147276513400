import React from 'react';
import "./career.scss";

const CareerCard = ({ data, col }) => {
    return (
        <>
            <div className={`col-md-${col ? col: 5} col-xl-3 mb-4`}>
                <div className="career-card bg-white">
                    <img src={data.img} alt="" className="img-fluid" /> 
                    <div className="bg-white py-4 px-4 pb-5">
                        <h4 className='font-bold'>{data.title}</h4>
                        <p className="font-16 mb-3">{data.details}</p>
                        {/* <button className="btn btn-outline-primary">VIEW DETAILS</button> */}
                        <a href={data.applyLink} className="btn btn-outline-primary btn-sm" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>
                        <a href={data.applyLink} className="btn btn-primary ml-3 btn-sm" target="_blank" rel="noopener noreferrer">APPLY NOW</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CareerCard;