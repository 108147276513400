import React from 'react';

const Oct22 = () => {
    const urlOne = "https://www.linkedin.com/posts/myelinh_healthtechnology-bci-neurotech-activity-6971454232017170432-FZtS?utm_source=share&utm_medium=member_desktop"
    const urlTwo = "https://www.gtec.at/bci-neurotech-masterclass-belgium/"
    const urlFour = "https://www.linkedin.com/feed/update/urn:li:activity:6981557142474399745/"
    const urlThree = "https://www.linkedin.com/posts/fonds-national-de-la-recherche_industrial-fellowships-results-2022-1-call-activity-6967771627023388672-inKh?utm_source=share&utm_medium=member_desktop"
    return (
        <>

            <article className="py-5 ">
                <div className="container text-center">
                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                        <div className="col-md-9 text-center">
                            <h3 className="text-left mb-4 font-bold">Myelin-H onto the Autumn Cohort of the MedTech Growth Series</h3>

                            <div className="mb-0 text-left">
                                <p>
                                    We are excited to announce that Myelin-H has been accepted onto the Autumn Cohort of the MedTech Growth Series, powered by Vantage - Startup Growth Partners! The program is designed to help UK-based MedTech startups grow their businesses and pave the way for a possible collaboration and partnership with the different HealthTech stakeholders in the UK, including the NHS. Thank you to Vantage - Startup Growth Partners for the opportunity to participate in this great six-week growth program in London.
                                </p>
                                <div className="text-center mb-4">
                                    <a href="https://www.linkedin.com/feed/update/urn:li:activity:6985980951537303553/" className='mt-4 btn btn-primary custom-round' target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 ">
                            <div className=" ">
                                <img src="/images/news/oct22/vantage.jpeg" alt="" className="img-fluid" />
                                <h6 className="text-center font-bold mt-3"><i>Vantage - Startup Growth Partners</i></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </article>
            <section className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <hr />
                    </div>
                </div>
            </section>
            <article className="py5 bg-light">
                <div className="container text-center">
                    <div className="row mt-4 d-flex align-items-center  justify-content-center">

                        <div className="col-md-9 text-center">
                            <div className="mb-0 text-left">
                                <p>
                                    It was an absolute pleasure for Myelin-H’s founders to be invited by his excellency, the ambassador of Belgium in the United Kingdom (UK) (Belgian Embassy) in his residence in London.
                                </p>
                                <div className="text-center mb-4">
                                    <a href={urlFour} className=" mt-4 btn btn-primary custom-round" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row no-gutters d-flex align-items-center">
                                <div className="col-8 ">
                                    <div className="px-4">
                                        <img src="/images/news/oct22/04.jpeg" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <img src="/images/news/oct22/05.jpeg" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            
            <article className="py5 bg-light">
                <div className="container text-center">
                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                        <div className="col-md-9 offset-md-1">
                            <div className="row">
                                <div className="col-md-8 text-center offset-md-2">
                                    <div className="">
                                        <img src="/images/team/starburst.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 text-center">
                            <div className="">
                                Myelin-H is now part of the starburst program.
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article className="py-5 bg-light">
                <div className="container text-center">
                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                        <div className="col-md-9 text-center">
                            <div className="mb-0 text-left">
                                Myelin-H is pleased to be invited by his excellency the ambassador of Belgium in the United Kingdom (UK) Mr. Bruno van der Pluijm to present its neurotechnology to help monitor & treat neurological diseases at the Belgian Embassy in London on Thursday 29 September 2022.
                            </div>
                            <a href={urlOne} className=" mt-4 btn btn-primary custom-round" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>
                        </div>
                        <div className="col-md-4 mt-4 offset-md-1">
                            <div className=" mr-md-5">
                                <img src="/images/news/oct22/01.jpeg" alt="" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </article>
            <article className="py-5 bg-">
                <div className="container text-center">
                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                    <div className="col-md-9 text-center">
                            <div className="mb-0 text-left">
                                Myelin-H's co-founders will be speaking at the brain-computer interface (BCI) & neurotech masterclass event in Belgium on the 28th of September at 9 AM (CET). You can register using this link:
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1">
                            <div className=" mt-md-5">
                                <img src="/images/news/oct22/02.jpeg" alt="" className="img-fluid" />
                            </div>
                            <a href={urlTwo} className=" mt-4 btn btn-primary custom-round" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>

                        </div>
                        
                    </div>
                </div>
            </article>
            <article className="py-5 bg-light">
                <div className="container text-center">


                    <div className="row mt-4 d-flex align-items-center justify-content-center">
                        <div className="col-md-9 text-center">
                            <div className="mb-0 text-left">
                                Public-private collaboration: 7 of 11 eligible projects have been retained for funding in the 2022-1 Industrial Fellowships Call.
                                <br /> ➤The Industrial Fellowships scheme awards PhD and Postdoc grants to researchers who carry out their PhD and/or postdoc training in collaboration with a company in Luxembourg. The scheme is open to all scientific domains, and to all researchers, regardless of their nationality. Collaborating companies must have a presence in Luxembourg.
                            </div>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <div className=" mt-md-4">
                                <img src="/images/news/oct22/03.png" alt="" className="img-fluid" />
                            </div>
                            <a href={urlThree} className=" mt-4 btn btn-primary custom-round" target="_blank" rel="noopener noreferrer">VIEW DETAILS</a>

                        </div>


                    </div>
                </div>
            </article>
        </>
    );
};

export default Oct22;