import AboutOurStory from "./AboutOurStory/AboutOurStory";
import AwardsAndTechnology from "./Awards/AwardsAndTechnology";
import ConceptVideo from "./ConceptVideo/ConceptVideo";
import DiseasesSponsors from "./DiseasesSponsors/DiseasesSponsors";
import ComponentHero from "./Hero/ComponentHero";
import ComponentHeroThree from "./Hero/ComponentHeroThree";
import ComponentHeroTwo from "./Hero/ComponentHeroTwo";
import MissionSection from "./MissionSection";
import Newsletter from "./Newsletter/Newsletter";
import OurSoftwareProducts from "./OurSoftwareProducts/OurSoftwareProducts";
import Solution from "./Solution/Solution";
import TeamAndAdvisory from "./TeamAndAdvisory/TeamAndAdvisory";
import ThereIsMore from "./ThereIsMore/ThereIsMore";
import VisionMission from "./VisionMission/VisionMission";
import WhatClientsSay from "./WhatClientsSay/WhatClientsSay";
import WhatWeDo from "./WhatWeDo/WhatWeDo";

const ViewHome = () => {

    document.title = "Myelin-H | Revolutionary software technology for remote diagnosis and monitoring of brain diseases";
    return (
        <>
            <main>
                <ComponentHero />
                <Solution />
                <OurSoftwareProducts />
                <WhatWeDo />
                <WhatClientsSay />
                <DiseasesSponsors />
                <TeamAndAdvisory />
                <AboutOurStory />
                <MissionSection />
                <ConceptVideo />
                <AwardsAndTechnology />
                <ThereIsMore/>
                <Newsletter />
            </main>

        </>
    );
};

export default ViewHome;