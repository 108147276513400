import { Steps } from 'antd';
import { stepsData } from './stepsData';
// import "../../../node_modules/antd/dist/antd.min.css"
import "./styles.scss"
// import "./steps-styles.scss"
const { Step } = Steps;


const ComponentSteps = () => {
    return (
        <>
      <div className="d-block d-md-none">
        <div class="ant-steps ant-steps-vertical ">
            {stepsData.map(item => <VerticalStep data={item}/> )}
        </div>
        </div>
            <div className="py-4 d-none d-md-block">
                <div class="ant-steps ant-steps-horizontal ant-steps-label-horizontal">
                    {stepsData.map(item => <HorizontalStep data={item}/> )}
                </div>
            </div> 
            
        </>
    );
};

export default ComponentSteps;


const VerticalStep = ({ data }) => {
    return (
        <>
            <div class="ant-steps-item ant-steps-item-process ant-steps-item-active mb-3">
                <div class="ant-steps-item-container">
                    <div class="ant-steps-item-tail"></div>
                    <div class="ant-steps-item-icon">
                        <span class="ant-steps-icon">{data.num} </span>
                    </div><div class="ant-steps-item-content">
                        <div class="ant-steps-item-title h5 font-weight-bold ">{data.title} </div>
                        <div class="ant-steps-item-description ">{data.text} </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const HorizontalStep = ({ data }) => {
    return (
        <>
            <div class="ant-steps-item ant-steps-item-process ant-steps-item-active">
                <div class="ant-steps-item-container">
                    <div class="ant-steps-item-tail"></div>
                    <div class="ant-steps-item-icon"><span class="ant-steps-icon">{data.num} </span></div>
                    <div class="ant-steps-item-content">
                        <div class="ant-steps-item-title h5 font-weight-bold ">{data.title} </div>
                        <div class="ant-steps-item-description">{data.text} </div>
                    </div>
                </div>
            </div>
        </>
    )
}

{/* <div className="text-center">
<div class="circle active">
    {data.num}
</div>
<h5 className="font-weight-bold">{data.text} </h5>
</div> */}

{/* <div className="d-block d-md-none">
        <div class="ant-steps ant-steps-vertical ">
            {stepsData.map(item => <VerticalStep data={item}/> )}
        </div>
        </div>
            <div className="py-5 d-none d-md-block">
                <div class="ant-steps ant-steps-horizontal ant-steps-label-horizontal">
                    {stepsData.map(item => <HorizontalStep data={item}/> )}
                </div>
            </div> */}
