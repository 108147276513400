import React from "react";

const October2023 = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 text-center">
                            <h2 className="font-bold text-center mb-1">
                                Myelin-H Got Nominated for Best Med-Tech
                                Software
                            </h2>

                            <p>
                                We are excited to share that Med-Tech World in
                                Malta (
                                <a
                                    href="https://med-tech.world/"
                                    target="_self"
                                >
                                    https://med-tech.world/
                                </a>
                                ) has nominated Myelin-H for the prestigious
                                Award &quot;Best MedTech Software Solution
                                Provider of the Year&quot;. MedTech Malta is an
                                investor and startup conference that brings
                                together ministers, venture capitalists,
                                hospital system venture arms, family offices,
                                angels, MedTech Startup entrepreneurs, and
                                industry partners to the beautiful Mediterranean
                                island of Malta, AKA &quot;The Rock&quot; from
                                18-20 October 2023. 
                            </p>
                            <div className="text-center">
                                <a
                                    href={
                                        "https://www.linkedin.com/feed/update/urn:li:activity:7112801968158294016"
                                    }
                                    className="mt-4 btn btn-primary custom-round"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    VIEW DETAILS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-1 mb-4">
                            <h2 className="font-bold  mb-1">
                                Welcome abroad Ali Stuckey!
                            </h2>

                            <p>
                                Please join us in warmly welcoming Ali Stuckey,
                                our new Marketing & Digital Media Specialist!{" "}
                                <br />
                                Ali Stuckey is a marketing, design, and
                                development professional based in Colorado, USA.
                                Ali Stuckey worked as Design & Media Specialist
                                at Paradromics Inc, a leading invasive
                                brain-computer interface (BCI) company. Though
                                she currently spends her days in B2B software
                                marketing, Ali brings years of experience in
                                fundraising strategy and program direction for
                                nonprofits and early-stage R&D startups. She is
                                highly passionate about emerging medical
                                technologies and their potential to transform
                                the lives of those with chronic illnesses.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <img
                                src="/images/team/ali-stuckey.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-1">
                            <img
                                src="/images/new-advisor.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h2 className="font-bold  mb-1">
                                Welcome abroad Dr janne-Henrik Parssinen!
                            </h2>

                            <p>
                                Myelin-H is delighted and honoured to welcome Dr
                                Janne-Henrik Parssinen to our advisory board. Dr
                                Parssinen will help Myelin-H with its business
                                development, GTM and strategic planning for its
                                medical products for clinical trials on
                                incurable neurodegenerative diseases and its
                                software-as-a-medical device for remote
                                monitoring and treatment of multiple sclerosis.
                                <br />
                                Dr Janne-Henrik Parssinen is a former director
                                at Roche (the pharmaceutical company) at the top
                                executive level with 25 years of experience,
                                including pharma, diagnostics and advanced
                                digital/information technologies. He has also
                                been actively involved as an advisor to the
                                imec. istart accelerator (by imec) and various
                                other digital health startups.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default October2023;
