import React from "react";
import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";

const TechWearableSensor = () => {
    return (
        <>
            <section id="solution" className="pt-5">
                <div className="container">
                    <h2 className="h2 text-primary text-center section-heading">
                        Brain health Interface
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <p className="mb-5 font-22 font-bld text-center">
                        Myelin-H meticulously captures{" "}
                        <strong>all the data</strong> needed to{" "}
                        <strong>monitor</strong> your <strong>brain</strong>{" "}
                        &amp; body <strong>health</strong>. Imagine{" "}
                        <strong>unlocking</strong> brain health insights even
                        while <strong>enjoying</strong> life&#39;s festivities
                        at parties, events, and <strong>concerts</strong>.
                    </p>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-md-3 px-4">
                            <img
                                src="/solutions/wearable.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        {/* <div className="col-md-4 px-4">
                            <img
                                src="/solutions/wearable-3.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div> */}
                        <div className="col-md-3 px-4">
                            <img
                                src="/solutions/wearable-2.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>

                        {/* <div className="col-md-5 mt-4">
                            <img src="/tech/interface.png" alt="" className="img-fluid" />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default TechWearableSensor;
