import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { timeStampToDate, timeStampToDateViaSecond } from "../../Utilities/convertTime";
// import { timeStampToDate } from '../../shared/utilities';

const PostCard = ({ col, blog }) => {
    return (
        <Col md={col ? col : 4} className="mb-3">
            <div className="card text-left">
                    <div>
                        <img
                            src={blog.featuredImage}
                            alt={blog.title}
                            className="img-fluid"
                        />
                        <div className="post-overlay"></div>
                        <div className="post-content px-4 mb-4">
                            {blog.tags && <span className="post-category text-primary">
                                <div className="my-2">
                                    {blog && blog.tags && blog.tags.map(item => <span className="btn btn-sm mr-1 btn-outline-primary">
                                        {item}
                                    </span> ) }
                                    
                                </div>
                            </span>}
                            <p className="font-16 mb-2 text-secondary">
                                {blog && timeStampToDateViaSecond(blog.createdAt)}
                            </p>
                            <h5 className="font-bold text-primary">
                                <>{blog.title} </>
                            </h5>
                            <Link
                                className="btn btn-primary"
                                to={"/blog/" + blog.id}
                            >
                                View details
                            </Link>

                            {/* <p className="text-white">{timeStampToDate(blog.createdAt)} &nbsp;&nbsp; | &nbsp; &nbsp; <FaEye /> &nbsp; {blog.totalViews} </p> */}
                        </div>
                    </div>
            </div>
        </Col>
    );
};

export default PostCard;
