import React, { useState } from "react";
import { Spotify } from "react-spotify-embed";

import CompPodcastCard from "./CompPodcastCard";
import CompOnScreenSpotifyPlayer from "./CompOnScreenSpotifyPlayer";
import YouTubeResponsiveEmbed from "../../Components/YouTubeResponsiveEmbed";
import allPodcasts from "./podcast-data";
const NeurotechPubHero = () => {
    const podCastURLs = [
        `https://podcasters.spotify.com/pod/show/themyelinpub/embed/episodes/Giovanni-Lauricella-Limited-Partner--Managing-Partner-of-Lifeblood--MedTech-Recruiter--and-Investor-e2h33d2/a-ab2lpkr`,
    ];

    const [activeEpisode, setActiveEpisode] = useState(null);
    const [showPlayer, setShowPlayer] = useState(false);

    return (
        <>
            {/* <section className=" contact-hero">
                <div className="container my-0 py-4 py-md-5">
                    <div className="text-container text-center text-white py-3 ">
                        <h1 className="font-weight-bold display-4">
                            The Myelin Pub
                        </h1>
                    </div>
                </div>
            </section> */}
            <section className="pub-cover-img ">
                {/* <img src="/myelin-pub-img/cover-pub.p" alt="" className="img-fluid" /> */}
            </section>
            <section className="">
                <div className="container py-4">
                    <div className="row align-items-center d-flex justify-content-center">
                        <div className="col-md-4">
                            <CompPodcastCard
                                data={allPodcasts[1]}
                                setShowPlayer={setShowPlayer}
                                setActiveEpisode={setActiveEpisode}
                                URL={podCastURLs[0]}
                                episode="02"
                            />
                        </div>
                        <YouTubeResponsiveEmbed
                            wrapperClass="col-md-5"
                            youTubeId="DnWuaFGkaGU"
                            radius="8"
                        />
                    </div>
                </div>
            </section>
            <section className="mb-4">
                <div className="container py-4">
                    <div className="row align-items-center d-flex justify-content-center">
                        <div className="col-md-4">
                            <CompPodcastCard
                                data={allPodcasts[0]}
                                episode="01"
                                setShowPlayer={setShowPlayer}
                                setActiveEpisode={setActiveEpisode}
                                URL={podCastURLs[0]}
                            />
                        </div>
                        <YouTubeResponsiveEmbed
                            wrapperClass="col-md-5"
                            youTubeId="DaaXGLQIWS8"
                            radius="8"
                        />
                    </div>
                </div>
            </section>
            <section className="purple-dark-bg py-4 pt-5 text-center">
                <h3 className="text-white font-bold">
                    Tune In, Tune Up... Delve
                </h3>
            </section>
            {/* <CompYoutubeSection/> */}
            {/* <section className="bg-light pt-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <CompPodcastFullPreview url={podCastURLs[0]} />
                        </div>
                    </div>
                </div>
            </section> */}
            <CompOnScreenSpotifyPlayer
                activeEpisode={activeEpisode}
                setShowPlayer={setShowPlayer}
                showPlayer={showPlayer}
            />
            <section className="bg-light py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            {/* <p>
                            <strong>Launching soon:</strong> "The Myelin Pub" debut season – a captivating rendezvous where brain health meets MedTech marvels in a sip of knowledge and mystery.

                            </p> <br /> */}
                            <p>
                                The Myelin Pub, hosted by Myelin-H Founders, is
                                a fortnightly podcast that invites you to a Tea
                                corner of enlightening conversations with
                                esteemed guests on brain health, covering
                                NeuroTech stories, brain disorders,
                                brain-machine interface, their marvels, and the
                                fascinating world of neurology.
                            </p>
                            <p>
                                From Investors and Venture Capitalists, Policy
                                Makers, and Researchers to Neurologists, Patient
                                Advocacy Groups, and Journalists, they will all
                                share their nuggets of wisdom and
                                behind-the-scenes stories and unravel the
                                mysteries of NeuroTech, Brain Diseases, Brain
                                Health, and Neuro-Ventures.
                            </p>
                            <p>
                                We will sit down with top-notch experts to cover
                                everything from venture funding and investment
                                for NeuroTech to technology trends, ethics
                                considerations, and regulatory hurdles.
                            </p>
                            <p>
                                Grab your cup of tea, take a sip, settle in, and
                                let's demystify the mysteries of these worlds
                                together!
                            </p>
                            <br />
                            <p>
                                The Myelin Pub: Tune In, Tune Up... Delve. Let's
                                demystify the mysteries together!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NeurotechPubHero;
