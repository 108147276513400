import React from 'react';
import CompIntroduction from './CompIntroduction';
import CompHowWeCollectData from './CompHowWeCollectData';
import CompHowWeSharePersonalData from './CompHowWeSharePersonalData';
import CompMiscelneousPolicies from './CompMiscelneousPolicies';

const ViewPrivacyPolicy = () => {
    return (
        <>
            <main>
                <CompIntroduction/>
                <CompHowWeCollectData/>
                <CompHowWeSharePersonalData/>
                <CompMiscelneousPolicies/>
            </main>
        </>
    );
};

export default ViewPrivacyPolicy;