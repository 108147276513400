import React from "react";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./LatestNews.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const June2024 = () => {
    const img1 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/973d7cf7-7011-84e9-a3fb-ede04c41b54c.jpeg`;
    const img2 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/f7a6f304-87e5-961c-28c7-382c360213c9.jpeg`;
    const img3 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/467578f1-4696-aee0-d301-e5bc170aee2d.jpeg`;
    const img4 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/94ec9983-1923-0940-c0f9-e4efb01d81bc.jpeg`;
    const img5 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/1bb1d93a-85e6-aa64-d50c-43baa996a6f8.jpeg`;
    const img6 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/954f58b8-9961-b3ba-cc6a-b5e9a28bf313.jpeg`;
    const img7 = `https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/501add4f-12b4-d416-453b-c8834f4457e8.jpeg`;

    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                Funding and R-MMS Clinical Trial - Official
                                Announcement
                            </h2>
                        </div>
                        <div className="col-md-6 mb-3">
                            <p className="font-16 text-justify">
                                We are delighted and excited to share this
                                funding news about our clinical trial R-MMS and
                                announce that we are on the cusp of commencing
                                this project in Luxembourg with the involvement
                                of more than six international hospitals from
                                four different continents. We are deeply
                                grateful to our advisers, team members,
                                partners, and investors, whose support and
                                contributions have played a crucial role in
                                reaching this significant milestone. This is
                                just the beginning, and we can't wait to share
                                more exciting news soon.
                                <br /> <br />
                                Press release (English):{" "}
                                <a
                                    href="https://lnkd.in/dtTuhuAn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://lnkd.in/dtTuhuAn
                                </a>
                                <br />
                                The Official press release (in French) can be
                                found here:{" "}
                                <a
                                    href="https://lnkd.in/emKdkTT3"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://lnkd.in/emKdkTT3
                                </a>
                            </p>
                        </div>

                        <div className="col-md-6">
                            <div className="img-card">
                                <img src={img1} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 2 */}
            <section className="py-5 ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                A Special Guest on the MyelinPub! 🧠🎙
                            </h2>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p className="font-16 text-justify">
                                Critical neurotech journalism is vitally
                                important in today's rapidly evolving world.
                                That's why we're delighted to announce the
                                release of the third episode of The MyelinPub in
                                July! 🧠🎙☕️🎉
                                <br /> <br />
                                Our third guest is someone truly exceptional. A
                                seasoned Forbes (US) writer and market analyst,
                                he has been covering healthcare for over a
                                decade. Through his insightful writing,
                                advising, and connecting, he has made
                                significant contributions to the field. His
                                critical technical journalism has been
                                invaluable for neurotech operators, investors,
                                entrepreneurs, recruiters, and commercial
                                leaders, helping them separate the signal from
                                the noise by objectively assessing neurotech
                                startups and the scientific foundations behind
                                their innovations. In this episode, our special
                                guest will share his journey, offering a glimpse
                                into the human being behind the neurotech writer
                                and analyst. He will also share his wisdom on
                                recent technological developments in
                                brain-computer interfaces (BCI) and highlight
                                BCI's potential to transform lives.
                            </p>
                        </div>

                        <div className="col-md-9">
                            <div className="img-card">
                                <img src={img2} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* news 3 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h2 className="font-bold mb-1 mb-3 text-center">
                                A New Partnership With The Prestigious IP Firm
                                Goodwin Procter in Boston
                            </h2>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p className="font-16 text-justify">
                                We're delighted to announce a transformative
                                partnership with Goodwin Procter LLP, one of the
                                largest law & IP firms in the world based in
                                Boston, US. This partnership will advance our US
                                expansion plans, investment, and intellectual
                                property (IP) strategy. It marks an instrumental
                                step towards achieving Myelin-H's vision of
                                becoming a digital neurotherapeutics and
                                brain-machine interface platform for universal
                                brain health monitoring and recovery –
                                essentially, a new operating system for the
                                brain. 🌟🏥
                            </p>
                        </div>

                        <div className="col-md-9">
                            <div className="img-card">
                                <img src={img3} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* news 4 */}
            <section className="py-5 ">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-9 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                                🧠 Exciting News - A New Myelinner!
                            </h2>
                            <p className="font-16 text-justify">
                                We are delighted to welcome our new
                                brain-computer interface (BCI) intern, Amulya
                                Mathur, joining us from Carnegie Mellon
                                University (CMU), in the US, whose exceptional
                                skills and passion will undoubtedly contribute
                                to the success and growth of Myelin-H!
                                <br />
                                <br />
                                <strong>Amulya</strong> is currently pursuing a
                                Master's degree at Carnegie Mellon University,
                                specialising in AI and Biomedical Engineering.
                                During the interview, Amulya expressed her
                                excitement about Myelin-H's mission and vision,
                                stating: “I resonate with Myelin-H's core
                                mission to develop a versatile, adaptive neural
                                interface to enhance the lives of those
                                suffering from multiple sclerosis significantly.
                                Myelin-H's commitment to pioneering cutting-edge
                                BCI technology for brain health monitoring and
                                neurorehabilitation is particularly inspiring to
                                me. ”
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="img-card">
                                <img src={img4} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 5 */}
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                            🌟 Keynote Presentation at the European Digital Healthtech Conference! 🌍🩺
                            </h2>
                            <p className="font-16 text-justify">
                            It was an absolute pleasure for Myelin-H and its team to have been part of the European Digital HealthTech Conference as a speaker and panellist. It was an incredible opportunity to share some of our brain-computer interface work en route to creating a universal brain health and neurotherapeutics platform for remote monitoring and neurorehabilitation of patients with brain disorders, starting with multiple sclerosis. It was delightful to exchange insights with leading experts and discuss the future of HealthTech in Europe, as well as shine a light on the various challenges lying ahead.

                                <br />
                                <br />
                                We extend our sincere gratitude to the Ministry of the Economy and Luxinnovation GIE team for the invitation and their great hospitality.


                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="img-card">
                                <img src={img5} alt="" className="" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="img-card">
                                <img src={img6} alt="" className="" />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <p className="font-14 text-primary mt-3">Courtesy and copyright of some of the photos to © Luxinnovation GIE
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* news 6 */}
            <section className="py-5 ">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-7 mb-3">
                            <h2 className="font-bold mb-1 text-center">
                            🌟 Sifted Interview
                            </h2>
                            <p className="font-16 text-justify">
                            Myelin-H was pleased to have an interview with Sifted and share its views and perspectives on a burgeoning HealthTech ecosystem in Luxembourg, which has irrefutably witnessed tremendous progress over the last few years. Our heartfelt thanks to Sadia Nowshin for the nice piece and brilliant questions. Many thanks to Startup Luxembourg for the invitation to share our thoughts and feedback on Luxembourg as a European HealthTech hub.

                                <br />
                                <br />
                                Read more: <a href="https://sifted.eu/articles/luxembourg-healthtech-hub-brnd" target="_blank" rel="noopener noreferrer">https://sifted.eu/articles/luxembourg-healthtech-hub-brnd</a>



                            </p>
                        </div>
                   
                        <div className="col-md-5">
                            <div className="img-card">
                                <img src={img7} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default June2024;
