import React from 'react';
import NeurotechPubHero from './NeurotechPubHero';
import "./myelin-pub.scss"

const ViewNeurotechPub = () => {
    return (
        <>
         <main>
            <NeurotechPubHero/>
            </main>   
        </>
    );
};

export default ViewNeurotechPub;