export const day_2 = {
    id: 2,
    title: `Winner of Care In Space Challenge`,
    subTitle: ``,
    date: `November 1, 2022`,
    url: `https://www.linkedin.com/posts/starburst-aerospace-accelerator_spacehealth-medtech-entrepreneurs-activity-6990707349099315200-PoYV?utm_source=share&utm_medium=member_desktop`,
    img: `/images/news/nov22/careInSpace.jpeg`,
    text: `
    <p>
    Myelin-H is extremely excited to be one of the winners of the Care In Space Program. <br/>
The program comes with funding, mentorship, and support for our plans & endeavors to focus on the US market, as well as to help us take our space medicine product "SpaceMirror" to the next level.

    </p>
    `
}