import React from 'react';
import HorizontalLine from '../../Components/common/HorizontalLine/HorizontalLine';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import { Link } from "react-router-dom";
import YouTubeResponsiveEmbed from '../../Components/YouTubeResponsiveEmbed';

const HomeTechnology = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Meet Our Team</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    {/* <p className="mb-5 font-22 font-bld text-center">
                        A combination of mobile <b>cognitive</b> <b>games</b>,  an <b>FDA-approved</b> wearable <b>sensor</b>, cutting-edge <b>neuromorphic software</b> for real-time biosignals processing, and a <b>clinical dashboard</b> for remote monitoring & management any time, anywhere.
                        <br />

                    </p> */}
                    <div className="text-center">
                        <Link to="/about-us" className="btn btn-primary btn-lg">KNOW THE MYELINNERS</Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeTechnology;