import React from "react";
import {
    FaArrowAltCircleLeft,
    FaArrowAltCircleRight,
    FaArrowRight,
} from "react-icons/fa";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import "./featuredIn.scss";
const FeaturedIn = () => {
    const article1 = `https://www.sueddeutsche.de/muenchen/wissenschaft-weltraumrobotern-das-fuehlen-beibringen-1.4698857`;
    const logo1 = `/featured/logo1.png`;
    const article2 = `https://background.tagesspiegel.de/digitalisierung/zied-tayeb`;
    const logo2 = `https://background.tagesspiegel.de/build/images/logo/background-logo.svg`;

    const article3 = `https://www.nasa.gov/directorates/spacetech/itech/2019_meet_the_innovators_MyelinS/`;
    const logo3 = `/featured/logo3.png`;

    const logo4 = `/featured/logo4.jpeg`;
    const article4 = `https://www.zaobao.com.sg/`;
    const news4 = `/featured/news4.jpeg`;
    return (
        <>
            <section>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-9">
                            <h2 className="h2 text-center section-heading">
                                Featured In
                            </h2>
                            <HorizontalLine center="true" className="mb-0" />
                            <div className="row d-flex align-items-center justify-content-center article-container text-center">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <a
                                        href={`https://www.prnewswire.com/news-releases/boryung-axiom-space-and-starburst-aerospace-announce-six-winners-from-the-inaugural-2022-care-in-space-challenge-301658821.html`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={`https://www.prnewswire.com/content/dam/prnewswire/homepage/prn_cision_logo_desktop.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                    {/* <a href={article1} className="btn-primary btn btn-sm" target="_blank" rel="noopener noreferrer">Süddeutsche Zeitung</a> */}
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <img
                                        src={logo3}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <a
                                    href="https://www.siliconluxembourg.lu/myelinh-the-founder-who-pivoted-to-neuroscience-to-help-his-mother/"
                                    
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/featured/Silicon.jpeg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                    {/* <a href={article1} className="btn-primary btn btn-sm" target="_blank" rel="noopener noreferrer">Süddeutsche Zeitung</a> */}
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <a
                                        href={logo1}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={logo1}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                    {/* <a href={article1} className="btn-primary btn btn-sm" target="_blank" rel="noopener noreferrer">Süddeutsche Zeitung</a> */}
                                </div>

                                <div className="col-md-4 mb-4 mb-md-0">
                                    <img
                                        src="/awards/featured.svg"
                                        alt=""
                                        className="img-fluid mt-3"
                                    />
                                    {/* <a href={`/awards/featured.svg`} className="mt-4 btn-primary btn btn-sm" target="_blank" rel="noopener noreferrer">Tagesspiegel <FaArrowAltCircleRight/> </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <div className="custom-news-container bg-light p-2">
                                <div className="d-block">
                                    <a
                                        href={article4}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={news4}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                    <a
                                        href={article4}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={logo4}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeaturedIn;
