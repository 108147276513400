export const day_1 = {
    id: 1,
    title: `Welcome New Myeliners`,
    subTitle: ``,
    date: `November 1, 2022`,
    url: `https://www.linkedin.com/posts/myelinh_team-myelinner-braincomputerinterface-activity-6993148988229050368-BO8h?utm_source=share&utm_medium=member_desktop`,
    img: `/images/news/nov22/newMyeliners.jpeg`,
    imgTwo: `/images/news/nov22/newMyeliners2.jpeg`,
    text: `
    <p>We are happy to welcome new Myelinners in our office in Luxembourg, who are now part of the Myelin-H family. We are excited to have you on board!
    </p>
    <p>More people (Myelinners) will join us in Manchester & Leuven shortly and we are constantly looking for more talents & good brains to join our team. If your appetite is high for neurotechnology, business, marketing, or space health, please send us your CV alongside a few words about yourself (info@myelinh.com).
    </p>
    `
}
