import ImgMultipleSclerosis from "./multiple-scolarsis.jpg";
import ImgAlzheimer from "./alzheimers-disease.jpg"
import ImgBrainCancer from "./childrens-brain-cancer.jpg"
import ImgParkinson from "./parkinsons-disease.jpg"
import "./DiseasesSponsors.scss"
import DelsysImg from "./delsys.png";
import DeLucaImg from "./de-luca.png"
import NvidiaImg from "./nvidia.png";
import HorizontalLine from '../../../Components/common/HorizontalLine/HorizontalLine';
import { Carousel } from "react-bootstrap"
export { NvidiaImg }
const DiseasesSponsors = () => {

    return (
        <>
            <section className="py-5 target-diseases">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Target Diseases</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row mt-5">
                        <div className="col-md-10 offset-md-1">
                            <Carousel interval={3000} fade={false} controls={false} indicators={false} >
                                <Carousel.Item>
                                    <div className="row d-flex align-items-center justify-content-center">
                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4"> Multiple Sclerosis</h3>
                                            <div className="car-img pb-4">
                                                <img src={ImgMultipleSclerosis} alt="" className="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4"> Mental Disorders</h3>
                                            <div className="car-img pb-4">
                                                <img src={`https://firebasestorage.googleapis.com/v0/b/myelin-ltd.appspot.com/o/xtrfgburu85atgiuqwn3.jpeg?alt=media&token=f3ac15f6-c0a3-40f2-9e65-76094c04b7d2`} alt="" className="" />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4">Parkinson’s Disease</h3>
                                            <div className="car-img pb-4">
                                                <img src={ImgParkinson} alt="" className="" />
                                            </div>
                                        </div> */}
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row d-flex align-items-centr">

                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4">Sleep Disorders</h3>
                                            <div className="car-img pb-4">
                                                <img src={ImgParkinson} alt="" className="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4"> Multiple Sclerosis</h3>
                                            <div className="car-img pb-4">
                                                <img src={ImgMultipleSclerosis} alt="" className="" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row d-flex align-items-centr">
                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4"> Mental Disorders</h3>
                                            <div className="car-img pb-4">
                                                <img src={`https://firebasestorage.googleapis.com/v0/b/myelin-ltd.appspot.com/o/xtrfgburu85atgiuqwn3.jpeg?alt=media&token=f3ac15f6-c0a3-40f2-9e65-76094c04b7d2`} alt="" className="" />
                                            </div>
                                        </div>

                                        <div className="col-md-6 px-5">
                                            <h3 className="text-center font-weight-bold mb-4">Sleep Disorders</h3>
                                            <div className="car-img pb-4">
                                                <img src={ImgParkinson} alt="" className="" />
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Sponsors</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row d-flex justify-content-center align-items-center">

                                <div className="col-md-4 mb-md-0 mb-4">
                                    <a href="http://delsys.com/">
                                        <img src={DelsysImg} alt="" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="col-md-4 mb-md-0 mb-4">
                                    <a href="http://delucafoundation.org/">
                                        <img src={DeLucaImg} alt="" className="img-fluid" />
                                    </a>
                                </div>
                                {/* <div className="col-md-4 mb-md-0 mb-4">
                                    <a href="https://www.nvidia.com/en-us/deep-learning-ai/startups/">
                                        <img src={NvidiaImg} alt="" className="img-fluid" />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DiseasesSponsors;