import React from 'react';
import HorizontalLine from '../../Components/common/HorizontalLine/HorizontalLine';

const HomePartners = () => {
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">Partners</h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-3 mb-md-0 mb-3">
                            <img src="/partners/axiom.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 mb-md-0 mb-3">
                            <img src="/partners/boryung.png" alt="" className="img-fluid" />
                        </div>
                        {/* <div className="col-md-2 mb-md-0 mb-3">
                            <img src="/partners/citycollege.webp" alt="" className="img-fluid" />
                        </div> */}
                        <div className="col-md-3 mb-md-0 mb-3">
                            <img src="/partners/delsys.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-3 mb-md-0 mb-3">
                            <img src="/partners/imec.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-2 mb-md-0 mb-3">
                            <img src="/partners/Langone.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-2 mb-md-0 mb-3">
                            <img src="/partners/stanford-medicine.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 mb-md-0 mb-3">
                            <img src="/partners/starburst.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 mb-md-0 mb-3">
                            <img src="/partners/deluca-foundation.webp" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-3 mb-md-0 mb-3">
                            <img src="/partners/FNR.png" alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-4 mb-md-0 mb-3">
                            <img src="/partners/imec-istart.jpg" alt="" className="img-fluid" />
                        </div>
                       
                 

                    </div>
                </div>
            </section>
        </>
    );
};

export default HomePartners;