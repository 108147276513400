const advisoryData = [
    {
        serial: 3,
        name: `Dr Janne henrik Parssinen`,
        designation: "Former Executive Director at Roche",
        designation2: "25 years of experience in pharma and diagnostics",
        img: `https://myelinh.com/images/new-advisor.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/janne-henrik-parssinen-b1420322/`,
        web: ``,
        youtube: ``,
        fb: ``,
        publicDescription: `<p>Dr Janne-Henrik Parssinen is a former director at Roche (the pharmaceutical company) at the top executive level with 25 years of experience, including pharma, diagnostics and advanced digital/information technologies. He has also been actively involved as an advisor to the imec. istart accelerator (by imec) and various other digital health startups.</p>
        `
    },
    
    // {
    //     serial: 1,
    //     name: `DR. CHRISTOPH THUEMMLER`,
    //     designation: `Director & Clinical Lead Helios Park Klinikum Leipzig`,
    //     designation2: `Professor of eHealth @ Edinburgh Napier`,
    //     img: `/team/christoph.png`,
    //     twitter: `https://twitter.com/ehealthchris?lang=en`,
    //     linkedin: `https://www.linkedin.com/in/christoph-thuemmler-b93575106/`,
    //     web: `https://www.helios-gesundheit.de/kliniken/leipzig-park-klinikum/unser-angebot/unsere-mitarbeiter/profil/person/christoph-thuemmler/`,
    //     youtube: ``,
    //     fb: ``,
    //     shortDescription: `<p>Dr. Christoph Thuemmler is a medical doctor and the director &amp; clinical Lead at Helios Park Klinikum Leipzig, as well as a professor of eHealth at Edinburgh Napier University with more than 20 years of experience in clinical studies.</p>`,
    //     references: [
    //         {
    //             text: "Google Scholar",
    //             link: "https://scholar.google.com/citations?user=CnpaaUUAAAAJ&hl=en",
    //             icon: "google"
    //         },
    //         {
    //             text: "Helios Gesundheit",
    //             link: "https://www.helios-gesundheit.de/kliniken/leipzig-park-klinikum/unser-angebot/unsere-mitarbeiter/profil/person/christoph-thuemmler/",
    //             icon: "web"
    //         }
    //     ]
    // },
    // {
    //     serial: 2,
    //     name: `RICARDO CHAVARRIAGA`,
    //     designation: `Head of the AI CLAIRE Office Switzerland`,
    //     twitter: `https://twitter.com/r_chavarriaga`,
    //     linkedin: `https://www.linkedin.com/in/chavarriaga/`,
    //     web: `http://ricardo.chavarriaga.me/`,
    //     youtube: ``,
    //     fb: ``,
    //     shortDescription: `<p>Dr. Ricardo Chavarriaga graduated as an engineer of the Pontificia Universidad Javeriana in Cali, Colombia, and received a Ph.D. degree in Computational Neuroscience from the Ecole Polytechnique Fédérale de Lausanne, EPFL. He is now focused on the interaction between humans and robots, particularly in the design of EEG-based Brain-Computer Interfaces (BCI). He is currently the Head of the CLAIRE Office Switzerland, which is the Confederation of Laboratories for Artificial Intelligence Research in Europe. He was senior research at Ecole Polytechnique Federale De Lausanne, EPFL from 2009 until 2019. He has more than 15 years of experience in brain-computer interfaces and biomedical engineering.</p>`,
    //     references: [
    //     {
    //         text: "Google Scholar",
    //         link: "https://scholar.google.com/citations?user=ZU1fTMYAAAAJ&hl=en",
    //         icon: "google"
    //     },
    //         {
    //             text: "Ricardo Chavarriaga",
    //             link: "https://rchavarriaga.github.io/",
    //             icon: "web"
    //         },
    //         {
    //             text: "ZHAW Zurich University",
    //             link: "https://www.zhaw.ch/en/about-us/person/chav/",
    //             icon: "web"
    //         }
    //     ]
    // },
    // {
    //     serial: 4,
    //     name: `OTHMAN BARAKA`,
    //     designation: `18 years of experience in Finance`,
    //     img: `/team/othman.png`,
    //     twitter: ``,
    //     linkedin: `https://www.linkedin.com/in/othman-baraka-86270823/`,
    //     web: ``,
    //     youtube: ``,
    //     fb: ``,
    //     shortDescription: `<p>Mr. Othman Baraka is a Chief Financial Officer in Luxembourg with 18 years of experience in business development, finance, and accounting.</p>`
    // },
   
    // {
    //     serial: 3,
    //     name: `DR. FATIMA AHMED`,
    //     designation: `Clinical Lead & Medical Doctor NHS Clinical Entrepreneur`,
    //     twitter: `https://twitter.com/fatima_alzara?lang=ca`,
    //     linkedin: `https://www.linkedin.com/in/dr-fatima-ahmed-38955414b/`,
    //     web: `https://www.orcha.co.uk/`,
    //     youtube: ``,
    //     fb: ``,
    //     shortDescription: `<p>Dr. Fatima Ahmed is a medical doctor specialising in Obstetrics &amp; Gynaecology, an NHS Clinical Entrepreneur Fellow, and Clinical Lead at Orcha (the organisation for the review of care and health apps). She has an interest in emerging technologies and how these can be used to solve some of today&#39;s biggest healthcare challenges.</p>
    //     `
    // },
   
    {
        serial: 5,
        name: `Dr. Stefan Harrer`,
        designation: `Ethical AI & Neuroscience Expert | Ex-IBM & Forbes Technology Council`,
        img: `https://myelinh.com/images/team/stefan.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/stefanharrer/`,
        web: ``,
        youtube: ``,
        fb: ``,
        publicDescription: `
        <p>
        Dr Stefan Harrer has wide expertise in Digital Health, Neurotech, and AI. Following corporate strategic advisory roles in the US and APAC as Scientific Assistant to the Lab Director of IBM Research Australia, Technical Assistant to IBM‘s VP of Cognitive Computing, and Member of the Neuroethics Advisory Group to the Director of IBM Research, he founded the Brain-Inspired Computing Program of IBM Research Australia. He has worked with clinical institutions such as Harvard Medical School and Boston Children’s Hospital the program has attracted investment of over $20M for his work. He is currently the Chief Innovation Officer of Digital Health Cooperative Research Centre Ltd., a $200M+ incubator and investment fund, and he is responsible for the pre-seed incubation strategy and data & AI and impact due diligence. He also serves as an advisor to start-ups, government agencies, and clinical institutions in the US and APAC. He holds 70 patents and his work and ideas have been featured in different magazines, such as ABC News, WIRED Magazine, The World Economic Forum, The Atlantic Council, and Popular Science.
        </p>
        `
    },
    {
        serial: 5,
        name: `Giovanni Lauricella`,
        designation: `Managing Partner of Lifeblood Capital, MedTech investor, and expert`,
        img: `https://myelinh.com/images/team/giovanni.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/giovannilauricella/`,
        web: ``,
        youtube: ``,
        fb: ``,
        publicDescription: `
        `
    },
    {
        serial: 3,
        name: `Dr. RICARDO CHAVARRIAGA`,
        designation: `Head of the CLAIRE Office Switzerland & Professor at The Zurich University of Applied Sciences`,
        img: `https://myelinh.com/images/team/ricardo.jpeg`,
        twitter: `https://twitter.com/r_chavarriaga`,
        linkedin: `https://www.linkedin.com/in/chavarriaga/`,
        web: `http://ricardo.chavarriaga.me/`,
        youtube: ``,
        fb: ``
    },
    {
        serial: 5,
        name: `Eulalia Codipietro`,
        designation: `20 years of experience in business development`,
        img: `https://myelinh.com/images/team/eulalia.jpeg`,
        twitter: ``,
        linkedin: `https://www.linkedin.com/in/eulalia-codipietro-384a031/`,
        web: ``,
        youtube: ``,
        fb: ``,
        publicDescription: `<p>Master in Business & Management; Master in Banking & Finance; 15 years in Business & Investment</p>`
    },
    
    // {
    //     serial: 5,
    //     name: `DR. DHOUHA DAASSI`,
    //     designation: `Research Scientist        @ Harvard Medical School`,
    //     twitter: ``,
    //     linkedin: `https://www.linkedin.com/in/dhouhadaassi/`,
    //     web: ``,
    //     youtube: ``,
    //     fb: ``
    // },

]

export { advisoryData }