import BCIHero from "./BCIHero/BCIHero"
import PodcastSection from "./PodcastSection/PodcastSection"
const BCI4MedicineBar = () => {
    return (
        <>
        <BCIHero/>
        <PodcastSection/>
        </>
    );
};

export default BCI4MedicineBar;