import AwardsAndTechnology from "./Awards/AwardsAndTechnology";
import FeaturedIn from "./FeaturedIn/FeaturedIn";
import ComponentHero from "./Hero/ComponentHero";
import HomePartners from "./HomePartners";
import HomeTechnology from "./HomeTechnology";
import LatestNews from "./LatestNews/LatestNews";
import MissionSection from "./MissionSection";
import Newsletter from "./Newsletter/Newsletter";
import WhatClientsSay from "./WhatClientsSay/WhatClientsSay";
import WhatWeDo from "./WhatWeDo/WhatWeDo";

const ViewHome = () => {

    document.title = "Myelin-H | Revolutionary software technology for remote diagnosis and monitoring of brain diseases";
    return (
        <>
            <main>
                <ComponentHero />
                <WhatWeDo />
                <MissionSection />
                <LatestNews/>
                <HomeTechnology />
                
                <WhatClientsSay />
                <HomePartners />
                <AwardsAndTechnology />
                <FeaturedIn />
                <Newsletter />
            </main>

        </>
    );
};

export default ViewHome;