import "./NewsHero.scss"


const NewsHero = () => {
    return (
        <section className="py-4 contact-hero">
            <div className="container py-1 py-md-4">
                <div className="text-container text-center text-white py-3 ">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-9">
                            <h1 className="font-weight-bold h1 text-center text-white" >
                                <br />
                                Welcome to our NEWS page. Here you’ll find the latest from Myelin-H.
                            </h1>
                        </div>
                    </div>


                    {/* <p className="font-22">Need to get in touch with the team? We are all ears.</p> */}
                </div>
            </div>
        </section>
    );
};

export default NewsHero;