import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import LaptopImg from "./medical-img.jpg";
import BCITech from "./brain-technology-computer.jpeg";
import "./Solution.scss";
import {
    MobileAppUiImg,
    WearableSensorImg,
} from "../../ProductOneBrainMirror/img";
import YouTubeResponsiveEmbed from "../../../Components/YouTubeResponsiveEmbed";
import { myelinhDemoVideoYTLink } from "../../../data/site-data";

const Solution = () => {
    return (
        <>
            {/* <section id="solution" className="py-5">
                <div className="container">
                    <h2 className="h2 text-primary text-center section-heading">
                        Solution
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                    <p className="mb-5 font-22 font-bld text-left">
                        Our <strong>cognitive games</strong> and{" "}
                        <strong>neuromorphic software technology</strong>{" "}
                        translate data into brain health insights and monitoring
                        scores. Our technology also helps perform{" "}
                        <strong>monitoring of astronauts</strong> in outer
                        space.
                    </p>
                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-4  ">
                            <div className="solutionCard text-center p-3 pb-4">
                                <div className="m-4">
                                    <img
                                        src={"/solutions/wearable.jpeg"}
                                        alt="wearable sensors"
                                        className="first"
                                    />
                                </div>
                                <h5 className="font-bold">
                                    Wearable sensors (Myelin-H toolkit):
                                </h5>
                                <p>
                                    Certified Mobile Sensors to capture various
                                    biosignals wirelessly and gel-free in less
                                    than a minute.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-4  ">
                            <div className="solutionCard text-center p-3 pb-4">
                                <div className=" my-4">
                                    <img
                                        src={MobileAppUiImg}
                                        alt="wearable sensors"
                                        className="second"
                                    />
                                </div>
                                <h5 className="font-bold">
                                    Myelin-H mobile app:
                                </h5>
                                <p>
                                    Our mobile app is designed to play our
                                    cognitive games while acquiring different
                                    health data in real-time.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-4  ">
                            <div className="solutionCard text-center p-3 pb-4">
                                <div className="m-4">
                                    <img
                                        src={BCITech}
                                        alt="wearable sensors"
                                        className="img-fluid"
                                    />
                                </div>
                                <h5 className="font-bold">
                                    Brain-Computer Interface (BCI) Software:
                                </h5>
                                <p>
                                    Our multi-modal neuro platform is capable of
                                    detecting health patterns and translating
                                    them into powerful insights and
                                    recommendation.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-4  ">
                            <div className="solutionCard text-center p-3 pb-4">
                                <div className="m-4">
                                    <img
                                        src={LaptopImg}
                                        alt="wearable sensors"
                                        className="third"
                                    />
                                </div>
                                <h5 className="font-bold">
                                    Clinical dashboard:
                                </h5>
                                <p>
                                    Our interactive clinical dashboard is
                                    designed to share results with medical
                                    experts anytime, anywhere.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="solution" className="py-5">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-4">
                            <h2 className="h2 text-primary text-center section-heading">
                                Tech Demo
                            </h2>
                            <HorizontalLine center="true" className="mb-" />
                            <p className="font-22 text-center">
                                Get a demonstration of the Myelin-H
                                neurotechnology for brain health.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <YouTubeResponsiveEmbed youTubeId={"TpMnmwY79SU"} />
                            {/* <YouTubeResponsiveEmbed youTubeId={"VBZ76e3sC8Y"} /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Solution;
