import React from "react";

const March2024 = () => {
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                     
                        <div className="col-md-6">
                        <h2 className="font-bold mb-1">
                            R-MMS Multi-Site & International Clinical Trial
                            Approved & Funded:
                        </h2>
                            <p>
                                We are pleased to share that Myelin-H's
                                international multi-site clinical trial (R-MMS)
                                on multiple sclerosis has been approved for
                                funding. <br />
                                Myelin-H will be on the cusp of starting a
                                24-month-long clinical trial from Luxembourg to
                                validate its hybrid brain-machine interface
                                R-MMS for remote Monitoring &
                                Neurorehabilitation of Multiple Sclerosis. The
                                trial has brought some of the most prestigious
                                and best clinical centres and hospitals
                                worldwide, including clinical centres from the
                                UK, the US, Australia, and more than four
                                European countries.
                            </p>
                        </div>

                        <div className="col-md-6">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/33382341-a91e-c6e8-263f-ceb57388e0ad.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light py-4">
                <div className="container">
                    <h2 className="font-bold mb-1">
                        Exciting News--The MyelinPub Podcast debuts its
                        groundbreaking first episode: The First Episode is
                        available! 🧠🎙{" "}
                        <a
                            href="https://www.youtube.com/watch?v=DaaXGLQIWS8"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Watch here
                        </a>
                    </h2>
                    <div className="row align-items-center">
                        <div className="col-md-5 text-center">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/02c498a2-d37a-8354-71bb-23dc7718bf19.png"
                                alt=""
                                className="img-fluid"
                            />
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/ac0deceb-a8a4-28b7-37ae-9be37cd4eb11.jpeg"
                                alt=""
                                className="w-50 mt-3"
                            />
                        </div>
                        <div className="col-md-7">
                            <p>
                                We are excited to share that the first MyelinPub
                                episode has been released. We are delighted to
                                unveil that MyelinPub's first guest is Giovanni
                                Lauricella, a limited partner (LP), Managing
                                Partner of Lifeblood, MedTech Executive
                                Recruiter, Investor, and Board Member. Based in
                                Florida (US), Giovanni brings 15 years of
                                experience designing and building teams
                                exclusively for the global MedTech & Neurotech
                                industry. Additionally, Giovanni is a board
                                member for publicly traded and privately held
                                medical device companies and an active angel
                                investor and limited partner for venture capital
                                funds. He has travelled to 45+ countries and
                                assisted 250+ startups with talent acquisition
                                and fundraising strategies, leading to over
                                2,000 hires, $300 million in facilitated
                                capital, and $4 billion in acquisitions of
                                companies he and his teams have recruited for.
                                Giovanni also sits on the advisory board of
                                Myelin-H. This conversation has it all, from
                                demystifying investment in MedTech and
                                recruitment to opening his heart and sharing his
                                life story, spilling the beans on Myelin-H's
                                secrets, and revealing why he decided to join
                                our board.
                                <br />
                                ☕🎉 Grab your cup of tea and watch the full
                                episode here:
                                https://www.youtube.com/watch?v=DaaXGLQIWS8
                                <br />
                                A short video of our conversation with the
                                MedTech & Neurotech maestro is here: <br />
                                <a
                                    href="https://youtu.be/eJ32XyGELHM?si=Gxxuvfs14oLVduxD"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                                <br />
                                <ul>
                                    <li>
                                        {" "}
                                        👉 YouTube:
                                        <a
                                            href="https://www.youtube.com/@TheMyelinPub"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://www.youtube.com/@TheMyelinPub
                                        </a>
                                    </li>
                                    <li>
                                        {" "}
                                        👉 Twitter:
                                        <a
                                            href="https://twitter.com/themyelinpub"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://twitter.com/themyelinpub
                                        </a>
                                    </li>
                                    <li>
                                        {" "}
                                        👉 Instagram:
                                        <a
                                            href="https://www.instagram.com/themyelinpub/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://www.instagram.com/themyelinpub/
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="container py-4">
                <h2 className="font-bold mb-1">
                    Team Expansion: Team enriched with five brilliant
                    Myelinners.
                </h2>
                <p>
                    Myelin-H has welcomed five new Myelinners from different
                    prestigious Universities, including Harvard Medical School
                    and Massachusetts General Hospital (Boston), Columbia
                    University in New York City, AstraZeneca, Sheffield
                    Hospital, and many other well-reputed Universities. A short
                    bio of our new Myelinners:
                </p>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">
                        <p>
                            <strong>Dr Sidharth Pancholi</strong> is a Postdoc
                            Research Fellow at Harvard Medical School and
                            Massachusetts General Hospital, joining us in
                            Luxembourg as a senior BCI scientist from Boston.
                        </p>
                    </div>
                    <div className="col-md-2">
                        <img
                            src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/f77606cb-2877-3809-ee04-7bb686af69ce.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-2">
                        <img
                            src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/5c7c4224-700c-5986-c980-15d04aedecc6.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-md-5">
                        <p>
                            <b>Srikanth Chandar</b> is a junior BCI engineer
                            joining us from Columbia University in New York
                            City.
                        </p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">
                        <p>
                            <b>Dr Praveenraj Elancheliyan </b> is a clinical
                            scientist joining us from the pharma company
                            AstraZeneca. He has been a clinical Trial Safety
                            Scientist for years, with 7+ years of experience in
                            clinical trials, cancer research, and medical
                            devices.
                        </p>
                    </div>
                    <div className="col-md-2">
                        <img
                            src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/14d746da-1f5b-26fe-eb6b-92150f6cb410.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-2">
                        <img
                            src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/bd7765f1-0ed4-e14a-07d6-65d504e62c06.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-md-5">
                        <p>
                            <b>Abdelrahman Ghanam</b> is a software engineer
                            joining us in Luxembourg from Egypt.
                        </p>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5">
                        <p>
                            <b>Sheila Mae Morte</b> is a sleep physiologist
                            joining us from a hospital in Sheffield in the UK.
                        </p>
                    </div>
                    <div className="col-md-2">
                        <img
                            src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/4888b4df-da2b-f92f-56cd-3d17d8c54100.png"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
            </section> */}
            <section className=" py-4">
                <div className="container">
                    <h2 className="font-bold mb-1">
                        💼🧠 🌐 Meet us in Amsterdam & Eindhoven - March 12-14,
                        2024! 🚀
                    </h2>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <p>
                                We're delighted to share that we'll be embarking
                                on a mission to Amsterdam & Eindhoven alongside
                                our esteemed partner imec.start. to delve into
                                the Dutch Market and engage with investors,
                                venture capitalists, hospitals, and potential
                                clinical and business partners.
                                <br /> From Tuesday, 12 March to Thursday, 14
                                March 2024, our team will be showcasing our
                                neurotechnology and brain-machine interface for
                                remote monitoring and neurorehabilitation of
                                patients with neurological diseases. 🤝 Curious
                                about the future of Brain-Computer Interfaces?
                                Join us for a firsthand look! Reach out for a
                                personalised demo during our visit.
                            </p>
                        </div>

                        <div className="col-md-5">
                            <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/9f39b10c-95f2-acf6-ea23-8acb4c0a53a0.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-4 bg-light">
                <div className="container">
                   
                    <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-5 pr-5 img-bg-remover bg-light">
<div className="pr-5">
<img
                                src="https://firebasestorage.googleapis.com/v0/b/ec-myelinh.appspot.com/o/dump%2Ffda.jpeg?alt=media&token=1c85d516-c877-4597-a558-02f0a8843964"
                                alt=""
                                className="img-fluid pr-5"
                            />
</div>
                        </div>
                        <div className="col-md-7">
                        <h2 className="font-bold mb-1">
                        FDA Breakthrough Device Designation Preparation:
                    </h2>
                            <p>
                                Myelin-H is on the cusp of a transformative
                                milestone as we prepare to submit our
                                Breakthrough Device Designation application in
                                the latter half of 2024. This signifies our
                                unwavering commitment to pioneering advancements
                                that redefine possibilities in neurotech.
                            </p>
                        </div>
                 
                    </div>
                </div>
            </section>
            {/* <section className="bg-light py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <h2 className="font-bold text-center mb-1">
                                JOIN OUR MISSION
                            </h2>
                            <p>
                                Every Myelinner is a dedicated missionary
                                focused on transforming the lives of brain
                                disease patients. Want to know more? Please read
                                Myelinner's Dress Code! 👗
                                <br /> It's not a suit and tie but a set of
                                guiding principles and tenets at La Familia,
                                defining who we are and the standards we set for
                                ourselves.
                                <br /> Link:{" "}
                                <a
                                    href="https://myelinh.com/blog/myelinner-dress-code-unveiling-myelin-hs-culture-and-guiding-principles"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                                <br /> Have you already read it, and do you feel
                                that our mission resonates with you and that our
                                cultural mottos align with yours? We would love
                                to hear from you. Please send us your
                                spontaneous application at{" "}
                                <a href="mailto:info@myelinh.com">
                                    info@myelinh.com
                                </a>
                                .
                                <br />
                                Remember to read our Become A Myelinner Blog,
                                which demystifies our recruitment process and
                                unravels avoidable banana skins we have observed
                                in previous applications.
                                <a
                                    href="https://myelinh.com/blog/join-the-myelin-h-team-unveiling-our-recruitment-process-tips-to-be%20come-a-myelinner-and-banana-skins-to-avoid"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                            </p>
                        </div>
                      
                        <div className="col-md-5 pb-5">
                        <img
                                src="https://mcusercontent.com/c4963d9debe06dc94962ebfe2/images/16a10a5e-4672-a73e-9c7a-597a3e7d2d9a.jpeg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default March2024;
