import {useState} from "react"
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { FaMinus, FaPlus } from "react-icons/fa"
import "./AccordionComponent.scss"


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));




const AccordionComponent = ({className, data}) => {
    const classes = useStyles();
    const [expandedIcon, setExpandedIcon] = useState(false)
    return (
        <>
          <div className={classes.root+ ` ${className} frequently-asked-component `}>
                <Accordion  onChange={()=> setExpandedIcon(!expandedIcon)} >
                    <AccordionSummary
                    
                        expandIcon={expandedIcon ?<MinusIcon />:<PlusIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h4 className="d-none d-md-block font-weight-bold mt-2 ml-3 text-white" >{data.qus} </h4>
                        <h5 className="d-block d-md-none font-weight-bold mt-2 ml-3 text-white" >{data.qus} </h5>
                    </AccordionSummary>
                    <AccordionDetails>
                       <p className="mx-md-4 text-white">
                       {data.ans}
                       </p>
                    </AccordionDetails>
                </Accordion>
                
            </div>   
        </>
    );
};

export default AccordionComponent;

const MinusIcon =()=>{
    return(
        <div className="text-white">
            <FaMinus/>
        </div>
    )
}
const PlusIcon =()=>{
    return(
        <div className="text-white">
            <FaPlus/>
        </div>
    )
}