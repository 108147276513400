import React from 'react';

const TargetBlankLink = ({link,text, email, className}) => {

    return (
        <>
            {email? <a href={`mailto:${link}`} className={className} target="_blank" rel="noopener noreferrer">{text}</a>:
            <a href={link} className={className} target="_blank" rel="noopener noreferrer">{text}</a>}
        </>
    );
};

export default TargetBlankLink;