import React from "react";

const CompHowWeSharePersonalData = () => {
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h3>5. How We Share Personal Data</h3>
                    <p>
                        With your consent, we may share your Personal Data with:
                    </p>
                    <ul>
                        <li>Health Professionals and your doctors.</li>
                        <li>
                            Authorities and others: This includes law
                            enforcement, government authorities, and private
                            parties whom we believe in good faith to be
                            necessary or appropriate to comply with the law or
                            legal process.
                        </li>
                        <li>
                            Business transferees: Such as acquirers and other
                            relevant participants in business transactions (or
                            negotiations for such transactions) involving a
                            corporate divestiture, merger, consolidation,
                            acquisition, reorganisation, sale, or other
                            disposition of all or any portion of the business or
                            assets of, or equity interests in, Myelin-H or our
                            affiliates (including, in connection with a
                            bankruptcy or similar proceedings).
                        </li>
                    </ul>
                </div>
            </section>
            <section className="py-5  bg-light">
                <div className="container">
                    <h3>6. Data Security and Retention of Personal Data:</h3>
                    <ul>
                        <li>
                            We implement various physical, technical,
                            organisational, and administrative security measures
                            to safeguard the Personal Data we gather. While we
                            strive to protect the privacy of your account and
                            other Personal Data stored in our records, it's
                            important to note that no security measures are
                            entirely fail-safe, and we cannot guarantee the
                            absolute security of your Personal Data.
                        </li>
                        <li>
                            We retain Personal Data for a duration that is
                            reasonably necessary for the purposes outlined in
                            this Privacy Policy, as long as we have a business
                            requirement to do so, or as mandated by law (e.g.,
                            for tax, legal, accounting, or other purposes),
                            whichever duration is longer.
                        </li>
                    </ul>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <h3>7. No Medical Advice:</h3>
                    <p>
                        Myelin-H offers Services to track, manage, and share
                        your brain health and cognitive wellness-related
                        information. The Services, along with any results or
                        content displayed via the Services, whether provided by
                        MYELIN-H or third parties, are not intended to provide
                        medical advice. They should not be considered as a
                        substitute for:
                    </p>
                    <ul>
                        <li>
                            Advice from your doctor or other medical
                            professionals.
                        </li>
                        <li>Diagnosis or treatment.</li>
                        <li>
                            A visit, call, or consultation with your doctor or
                            other medical professionals.
                        </li>
                    </ul>
                    <p>
                        The Services do not aim to treat or prevent any medical
                        condition. All content available through the Services is
                        for general informational purposes only. Utilising the
                        Services or communicating with us via the Internet,
                        email, or other means does not establish a
                        doctor-patient relationship. If you have any
                        health-related inquiries, please contact or consult your
                        doctor or other healthcare provider. It is crucial not
                        to disregard medical advice or delay seeking it due to
                        any content presented on the Services. Furthermore, you
                        should refrain from using the Services or any content on
                        the Services for diagnosing or treating a health
                        problem. Always consult with a qualified and licensed
                        medical professional.
                    </p>
                </div>
            </section>
        </>
    );
};

export default CompHowWeSharePersonalData;
